import Styles from "./Footer.module.css";

import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <footer className={Styles["footer"]}>
        <div className={Styles["logo__footer"]}>
          <Link to="/" className={Styles["logo"]}>
            <img
              className={Styles["logo"]}
              src="/img/logo/logo.svg"
              alt="логотип dst"
            />
          </Link>
          <p className={Styles["right__footer"]}>© Все права защищены</p>
        </div>
        <div className={Styles["content__footer"]}>
          <div className={Styles["nav__footer"]}>
            <Link to="rinks" className={Styles["link__footer"]}>
              Катки
            </Link>
            <Link to="pavers" className={Styles["link__footer"]}>
              Асфальтоукладчики
            </Link>
            <Link to="overloads" className={Styles["link__footer"]}>
              Перегружатели
            </Link>
          </div>
          <div className={Styles["nav__footer"]}>
            <Link to="contacts" className={Styles["link__footer"]}>
              Контакты
            </Link>
            <a href="tel:+7 (916) 830-40-73" className={Styles["link__footer"]}>
              +7 (916) 830-40-73
            </a>
            <a
              href="mailto:dst2024@yandex.ru"
              className={Styles["link__footer"]}
            >
              dst2024@yandex.ru
            </a>
          </div>
          <div className={Styles["social__footer"]}>
            <h3 className={Styles["social-title__footer"]}>Наши соцсети</h3>
            <div className={Styles["links__social"]}>
              <Link
                to="https://youtube.com/@dynapacru9560?si=1yxsVqNCEktZF1M3"
                className={Styles["youtube__footer"]}
              ></Link>
              <Link to="/" className={Styles["whatsapp__footer"]}></Link>
              <Link to="/" className={Styles["tg__footer"]}></Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
