import Styles from "./ContactsPage.module.css";
import { MapCompany } from "../../components/MapCompany/MapCompany";
import { Contacts } from "../../components/Contacts/Contacts";

export const ContactsPage = () => {
  return (
    <section className={Styles["contact"]}>
      <h2 className={Styles["title__contact"]}>контакты</h2>
      <MapCompany />
      <Contacts />
    </section>
  );
};
