export const dataPaver = [
  {
    id: 1,
    title: "F1250CS (China)",
    image: "https://storage.yandexcloud.net/dst-storage/img/pavers/f1250cs.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/pavers/f1250cs_ru.pdf",
    desc: [
      "Заниженная разгрузочная высота приемного бункера оптимальна для выгрузки материала из любых типов самосвалов;",
      "Широкие возможности трансформации створок бункера, в том числе для боковой загрузки материалом;",
      "Реверсивный раздельный транспортер;",
      "Ультразвуковые датчики заполнения обеспечивают равномерное перемещение материала шнеком;",
      "Электрический подогрев выглаживающей плиты – быстрый и равномерный нагрев рабочих поверхностей;",
      "Идеальная ровность покрытия от выглаживающих плит DYNAPAC;",
      "Двигатель Deutz – экономичная и безотказная работа в самых жестких условиях;",
      "Эргономичный пульт управления обладает логическим разделением функциональных зон;",
      "Мощный гусеничный привод позволяет перемещаться по любым, даже рыхлым и неукрепленным основаниям;",
      "Превосходная сервисная доступность компонентов.",
    ],

    systemControl: "Аналоговая",

    layCapTheory: 300,
    maxLayThick: 200,
    minLayThick: "3,5",

    basicLayThick: "1,2",
    workHydraulicBroad: "2,4",
    workMax: "3,5",
    minMax: "0,5",

    operatingWeight: "7,0",
    workSpeed: "0 - 27",
    transportSpeed: "0 - 3,3",
    entranceAngle: 13,

    chassisBase: 1390,
    trackWidth: 200,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 5,
    bunkerInnerWidth: 2830,

    conveyorManage: "Реверсивный",
    conveyorWidth: 620,

    augerManage: "Раздельное реверсивное управление,\n левой и правой стороной",
    augerDiam: 320,
    augerHeightLift: 150,

    motorManage:
      "Электронное,\n с бесступенчатой регулировкой\n оборотов и экономичным\n режимом EcoMode",
    motorModel: "Deutz TD2.9 L4",
    motorMinimalPower: "54 (72)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 85,

    smoothPlateModel: "V2400TVE, электрический",
    widthSmoothPlate: "1,20 / 2,40 / 3,50",
    freqRammingBeam: 1500,
    freqVibrator: 3000,
    transverseFracturePlate: "-2,5 / +4,5",

    parameterA: 1400,
    parameterB1: 2820,
    parameterB2: 1430,
    parameterB3: 940,
    parameterC1: 200,
    parameterH2: 2200,
    parameterH3: 570,
    parameterL1: 4760,
    parameterL2: 4450,
    parameterL3: 1640,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/F1250CS/f1250cs_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/F1250CS/f1250cs_2.png",
    ],
  },
  {
    id: 2,
    title: "SD2500C",
    image: "https://storage.yandexcloud.net/dst-storage/img/pavers/sd2500c.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/pavers/sd2500c_ru.pdf",
    desc: [
      "Первоклассные эксплуатационные характеристики;",
      "Высокое качество и надежность;",
      "Превосходная жесткость плиты во всем диапазоне рабочей ширины укладки;",
      "Система демпфирования подкатных роликов Safe-Impact (в зависимости от комплектации);",
      "Заниженная разгрузочная высота приемного бункера оптимальна для выгрузки материала из любых типов самосвалов;",
      "Сверхкомпактный центральный привод шнека;",
      "Система ускоренного электрического либо газового подогрева плиты;",
      "Рекордно низкий уровень шума – всего 104 дБ;",
      "Великолепный обзор всех рабочих зон асфальтоукладчика;",
      "Экономичный двигатель с системой Eco-Mode;",
      "Пульты управления плитой с интегрированной системой нивелирования Moba-Matic;",
      "Складываемые боковые щитки;",
      "Сдвигаемая на 500мм в обе стороны платформа оператора с гидроприводом;",
      "Полный пакет светодиодного рабочего освещения;",
      "Система удаленного мониторинга Dyn@Link;",
      "Превосходная сервисная доступность к узлам и агрегатам;",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 700,
    maxLayThick: 310,
    minLayThick: 9,

    basicLayThick: "2,55",
    workHydraulicBroad: "5,1",
    workMax: "8,8",
    minMax: "2,05",

    operatingWeight: "18,5",
    workSpeed: "0 - 28",
    transportSpeed: "0 - 4",
    entranceAngle: 13,

    chassisBase: 2900,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 12,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: "555 / 560",

    conveyorManage:
      "Раздельное реверсивное управление, левой и правой стороной",
    conveyorWidth: "2х655",

    augerManage: "Раздельное реверсивное управление, левой и правой стороной",
    augerDiam: 380,
    augerHeightLift: 250,

    motorManage:
      "Электронное,\n с бесступенчатой регулировкой\n оборотов и экономичным\n режимом EcoMode",
    motorModel: "Сummins, QSB 6.7–C173",
    motorMinimalPower: "129 (175)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 320,

    smoothPlateModel: "V5100TVE, электрический",
    widthSmoothPlate: "2,55 / 5,10 / 8,80",
    freqRammingBeam: 1500,
    freqVibrator: 3000,
    transverseFracturePlate: "-2,5 / +4,5",

    parameterA: 2900,
    parameterB1: 3400,
    parameterB2: 2550,
    parameterB3: 1990,
    parameterC1: 320,
    parameterH1: 3900,
    parameterH2: 3100,
    parameterH3: 490,
    parameterL1: 6150,
    parameterL3: 2120,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2500C/sd2500c_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2500C/sd2500c_2.png",
    ],
  },
  {
    id: 3,
    title: "SD2500CS",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/pavers/sd2500cs.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/pavers/sd2500cs_ru.pdf",
    desc: [
      "Первоклассные эксплуатационные характеристики;",
      "Высокое качество и надежность;",
      "Превосходная жесткость плиты во всем диапазоне рабочей ширины укладки;",
      "Система демпфирования подкатных роликов Safe-Impact (в зависимости от комплектации);",
      "Заниженная разгрузочная высота приемного бункера оптимальна для выгрузки материала из любых типов самосвалов;",
      "Сверхкомпактный центральный привод шнека;",
      "Система ускоренного электрического либо газового подогрева плиты;",
      "Рекордно низкий уровень шума – всего 104 дБ;",
      "Великолепный обзор всех рабочих зон асфальтоукладчика;",
      "Экономичный двигатель с системой Eco-Mode;",
      "Пульты управления плитой с интегрированной системой нивелирования Moba-Matic;",
      "Складываемые боковые щитки;",
      "Сдвигаемая на 500мм в обе стороны платформа оператора с гидроприводом;",
      "Полный пакет светодиодного рабочего освещения;",
      "Система удаленного мониторинга Dyn@Link;",
      "Превосходная сервисная доступность к узлам и агрегатам;",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 800,
    maxLayThick: 310,
    minLayThick: 10,

    basicLayThick: "2,55",
    workHydraulicBroad: "5,1",
    workMax: "8,8",
    minMax: "2,05",

    operatingWeight: "18,5",
    workSpeed: "0 - 28",
    transportSpeed: "0 - 4",
    entranceAngle: 13,

    chassisBase: 2900,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 12,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: "555 / 560",

    conveyorManage:
      "Раздельное реверсивное управление, левой и правой стороной",
    conveyorWidth: "2х655",

    augerManage: "Раздельное реверсивное управление, левой и правой стороной",
    augerDiam: 380,
    augerHeightLift: 250,

    motorManage:
      "Электронное,\n с бесступенчатой регулировкой\n оборотов и экономичным\n режимом EcoMode",
    motorModel: "Сummins, QSB 6.7–C173",
    motorMinimalPower: "142 (193)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 320,

    smoothPlateModel: "V5100TVE, электрический",
    widthSmoothPlate: "2,55 / 5,10 / 8,80",
    freqRammingBeam: 1500,
    freqVibrator: 3000,
    transverseFracturePlate: "-2,5 / +4,5",

    parameterA: 2900,
    parameterB1: 3400,
    parameterB2: 2550,
    parameterB3: 1990,
    parameterC1: 320,
    parameterH1: 3900,
    parameterH2: 3100,
    parameterH3: 490,
    parameterL1: 6150,
    parameterL3: 2120,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2500CS/sd2500cs_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2500CS/sd2500cs_2.png",
    ],
  },
  {
    id: 4,
    title: "SD2500C (China)",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/pavers/sd2500c_cn.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/pavers/sd2500c_ru.pdf",
    desc: [
      "Первоклассные эксплуатационные характеристики;",
      "Высокое качество и надежность;",
      "Превосходная жесткость плиты во всем диапазоне рабочей ширины укладки;",
      "Заниженная разгрузочная высота приемного бункера оптимальна для выгрузки материала из любых типов самосвалов;",
      "Сверхкомпактный центральный привод шнека;",
      "Система ускоренного электрического либо газового подогрева плиты;",
      "Рекордно низкий уровень шума – всего 104 дБ;",
      "Великолепный обзор всех рабочих зон асфальтоукладчика;",
      "Экономичный двигатель с системой Eco-Mode;",
      "Пульты управления плитой с интегрированной системой нивелирования Moba-Matic;",
      "Складываемые боковые щитки;",
      "Сдвигаемая на 500мм в обе стороны платформа оператора с гидроприводом;",
      "Полный пакет светодиодного рабочего освещения;",
      "Система удаленного мониторинга Dyn@Link;",
      "Превосходная сервисная доступность к узлам и агрегатам;",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 700,
    maxLayThick: 310,
    minLayThick: 9,

    basicLayThick: "2,55",
    workHydraulicBroad: "5,1",
    workMax: "8,8",
    minMax: "2,05",

    operatingWeight: "18,5",
    workSpeed: "0 - 28",
    transportSpeed: "0 - 4",
    entranceAngle: 13,

    chassisBase: 2900,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 12,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: "555 / 560",

    conveyorManage:
      "Раздельное реверсивное управление, левой и правой стороной",
    conveyorWidth: "2х655",

    augerManage: "Раздельное реверсивное управление, левой и правой стороной",
    augerDiam: 380,
    augerHeightLift: 250,

    motorManage:
      "Электронное,\n с бесступенчатой регулировкой\n оборотов и экономичным\n режимом EcoMode",
    motorModel: "Сummins, QSB 6.7–C173",
    motorMinimalPower: "129 (175)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 320,

    smoothPlateModel: "V5100TVE, электрический",
    widthSmoothPlate: "2,55 / 5,10 / 8,80",
    freqRammingBeam: 1500,
    freqVibrator: 3000,
    transverseFracturePlate: "-2,5 / +4,5",

    parameterA: 2900,
    parameterB1: 3400,
    parameterB2: 2550,
    parameterB3: 1990,
    parameterC1: 320,
    parameterH1: 3900,
    parameterH2: 3100,
    parameterH3: 490,
    parameterL1: 6150,
    parameterL3: 2120,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2500C_CN/sd2500ccn_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2500C_CN/sd2500ccn_2.png",
    ],
  },
  {
    id: 5,
    title: "SD2500CS (China)",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/pavers/sd2500cs_cn.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/pavers/sd2500cs_ru.pdf",
    desc: [
      "Первоклассные эксплуатационные характеристики;",
      "Высокое качество и надежность;",
      "Превосходная жесткость плиты во всем диапазоне рабочей ширины укладки;",
      "Заниженная разгрузочная высота приемного бункера оптимальна для выгрузки материала из любых типов самосвалов;",
      "Сверхкомпактный центральный привод шнека;",
      "Система ускоренного электрического либо газового подогрева плиты;",
      "Рекордно низкий уровень шума – всего 104 дБ;",
      "Великолепный обзор всех рабочих зон асфальтоукладчика;",
      "Экономичный двигатель с системой Eco-Mode;",
      "Пульты управления плитой с интегрированной системой нивелирования Moba-Matic;",
      "Складываемые боковые щитки;",
      "Сдвигаемая на 500мм в обе стороны платформа оператора с гидроприводом;",
      "Полный пакет светодиодного рабочего освещения;",
      "Система удаленного мониторинга Dyn@Link;",
      "Превосходная сервисная доступность к узлам и агрегатам;",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 800,
    maxLayThick: 310,
    minLayThick: 10,

    basicLayThick: "2,55",
    workHydraulicBroad: "5,1",
    workMax: "8,8",
    minMax: "2,05",

    operatingWeight: "18,5",
    workSpeed: "0 - 28",
    transportSpeed: "0 - 4",
    entranceAngle: 13,

    chassisBase: 2900,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 12,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: "555 / 560",

    conveyorManage:
      "Раздельное реверсивное управление, левой и правой стороной",
    conveyorWidth: "2х655",

    augerManage: "Раздельное реверсивное управление, левой и правой стороной",
    augerDiam: 380,
    augerHeightLift: 250,

    motorManage:
      "Электронное,\n с бесступенчатой регулировкой\n оборотов и экономичным\n режимом EcoMode",
    motorModel: "Сummins, QSB 6.7–C173",
    motorMinimalPower: "142 (193)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 320,

    smoothPlateModel: "V5100TVE, электрический",
    widthSmoothPlate: "2,55 / 5,10 / 8,80",
    freqRammingBeam: 1500,
    freqVibrator: 3000,
    transverseFracturePlate: "-2,5 / +4,5",

    parameterA: 2900,
    parameterB1: 3400,
    parameterB2: 2550,
    parameterB3: 1990,
    parameterC1: 320,
    parameterH1: 3900,
    parameterH2: 3100,
    parameterH3: 490,
    parameterL1: 6150,
    parameterL3: 2120,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2500CS_CN/sd2500csc_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2500CS_CN/sd2500csc_2.png",
    ],
  },
  {
    id: 6,
    title: "SD2550C",
    image: "https://storage.yandexcloud.net/dst-storage/img/pavers/sd2550c.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/pavers/sd2550c_en.pdf",
    desc: [
      "Первоклассные эксплуатационные характеристики;",
      "Высокое качество и надежность;",
      "Превосходная жесткость плиты во всем диапазоне рабочей ширины укладки;",
      "Система демпфирования подкатных роликов Safe-Impact (в зависимости от комплектации);",
      "Заниженная разгрузочная высота приемного бункера оптимальна для выгрузки материала из любых типов самосвалов;",
      "Сверхкомпактный центральный привод шнека;",
      "Система ускоренного электрического либо газового подогрева плиты;",
      "Рекордно низкий уровень шума – всего 104 дБ;",
      "Великолепный обзор всех рабочих зон асфальтоукладчика;",
      "Экономичный двигатель с системой Eco-Mode;",
      "Пульты управления плитой с интегрированной системой нивелирования Moba-Matic;",
      "Складываемые боковые щитки;",
      "Сдвигаемая на 500мм в обе стороны платформа оператора с гидроприводом;",
      "Полный пакет светодиодного рабочего освещения;",
      "Система удаленного мониторинга Dyn@Link;",
      "Превосходная сервисная доступность к узлам и агрегатам;",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 900,
    maxLayThick: 360,
    minLayThick: 12,

    basicLayThick: "2,55",
    workHydraulicBroad: "5,1",
    workMax: "8,8",
    minMax: "2,05",

    operatingWeight: "20,0",
    workSpeed: "0 - 28",
    transportSpeed: "0 - 4",
    entranceAngle: 13,

    chassisBase: 3360,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 15,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: "525 / 530",

    conveyorManage:
      "Раздельное реверсивное управление, левой и правой стороной",
    conveyorWidth: "2х655",

    augerManage: "Раздельное реверсивное управление, левой и правой стороной",
    augerDiam: 430,
    augerHeightLift: 250,

    motorManage:
      "Электронное,\n с бесступенчатой регулировкой\n оборотов и экономичным\n режимом EcoMode",
    motorModel: "Сummins, QSB 6.7–C173",
    motorMinimalPower: "164 (223)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 320,

    smoothPlateModel: "V5100TVE, электрический",
    widthSmoothPlate: "2,55 / 5,10 / 8,80",
    freqRammingBeam: 1500,
    freqVibrator: 3000,
    transverseFracturePlate: "-2,5 / +4,5",

    parameterA: 3360,
    parameterB1: 3470,
    parameterB2: 2550,
    parameterB3: 1990,
    parameterC1: 320,
    parameterH1: 3900,
    parameterH2: 3100,
    parameterH3: 460,
    parameterL1: 6630,
    parameterL3: 2600,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2550C/sd2550c_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2550C/sd2550c_2.png",
    ],
  },
  {
    id: 7,
    title: "SD2550CS",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/pavers/sd2550cs.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/pavers/sd2550cs_en.pdf",
    desc: [
      "Первоклассные эксплуатационные характеристики;",
      "Высокое качество и надежность;",
      "Превосходная жесткость плиты во всем диапазоне рабочей ширины укладки;",
      "Система демпфирования подкатных роликов Safe-Impact (в зависимости от комплектации);",
      "Заниженная разгрузочная высота приемного бункера оптимальна для выгрузки материала из любых типов самосвалов;",
      "Сверхкомпактный центральный привод шнека;",
      "Система ускоренного электрического либо газового подогрева плиты;",
      "Рекордно низкий уровень шума – всего 104 дБ;",
      "Великолепный обзор всех рабочих зон асфальтоукладчика;",
      "Экономичный двигатель с системой Eco-Mode;",
      "Пульты управления плитой с интегрированной системой нивелирования Moba-Matic;",
      "Складываемые боковые щитки;",
      "Сдвигаемая на 500мм в обе стороны платформа оператора с гидроприводом;",
      "Полный пакет светодиодного рабочего освещения;",
      "Система удаленного мониторинга Dyn@Link;",
      "Превосходная сервисная доступность к узлам и агрегатам;",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 1100,
    maxLayThick: 360,
    minLayThick: 14,

    basicLayThick: "2,55",
    workHydraulicBroad: "5,1",
    workMax: "8,8",
    minMax: "2,05",

    operatingWeight: "20,0",
    workSpeed: "0 - 28",
    transportSpeed: "0 - 4",
    entranceAngle: 13,

    chassisBase: 3360,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 12,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: "525 / 530",

    conveyorManage:
      "Раздельное реверсивное управление, левой и правой стороной",
    conveyorWidth: "2х655",

    augerManage: "Раздельное реверсивное управление, левой и правой стороной",
    augerDiam: 500,
    augerHeightLift: 250,

    motorManage:
      "Электронное,\n с бесступенчатой регулировкой\n оборотов и экономичным\n режимом EcoMode",
    motorModel: "Сummins, QSB 6.7–C260",
    motorMinimalPower: "194 (264)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 320,

    smoothPlateModel: "V5100TVE, электрический",
    widthSmoothPlate: "2,55 / 5,10 / 8,80",
    freqRammingBeam: 1500,
    freqVibrator: 3000,
    transverseFracturePlate: "-2,5 / +4,5",

    parameterA: 3360,
    parameterB1: 3470,
    parameterB2: 2550,
    parameterB3: 1990,
    parameterC1: 320,
    parameterH1: 3900,
    parameterH2: 3100,
    parameterH3: 460,
    parameterL1: 6630,
    parameterL3: 2600,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2550CS/sd2550cs_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/SD2550CS/sd2550cs_2.png",
    ],
  },
  {
    id: 8,
    title: "DRF25-80C (China)",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drf25_80c.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/pavers/drf25_80c_ru.pdf",
    desc: [
      "Первоклассные эксплуатационные характеристики;",
      "Высокое качество и надежность;",
      "Превосходная жесткость плиты во всем диапазоне рабочей ширины укладки;",
      "Заниженная разгрузочная высота приемного бункера оптимальна для выгрузки материала из любых типов самосвалов;",
      "Аналоговая система управления;",
      "Сверхкомпактный центральный привод шнека;",
      "Гидравлическая регулировка шнека по высоте;",
      "Система ускоренного электрического подогрева плиты;",
      "Рекордно низкий уровень шума – всего 104 дБ;",
      "Великолепный обзор всех рабочих зон асфальтоукладчика;",
      "Превосходная ремонтопригодность.",
    ],

    systemControl: "Аналоговая система управления",

    layCapTheory: 800,
    maxLayThick: 310,
    minLayThick: "2,55",

    basicLayThick: "2,55",
    workHydraulicBroad: "5,1",
    workMax: "8,8",
    minMax: "2,55",

    operatingWeight: "18,2",
    workSpeed: "0 - 30",
    transportSpeed: "0 - 4",
    entranceAngle: 13,

    chassisBase: 3360,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: "17,0",
    bunkerInnerWidth: 3400,
    bunkerHeightUnload: "525 / 530",

    conveyorManage:
      "Раздельное реверсивное управление, левой и правой стороной",
    conveyorWidth: "2х655",

    augerManage: "Раздельное реверсивное управление",
    augerDiam: 380,
    augerHeightLift: 250,

    motorManage:
      "Электронное,\n с бесступенчатой регулировкой\n оборотов и экономичным\n режимом EcoMode",
    motorModel: "Dongfeng-Cummins B5.9-C210",
    motorMinimalPower: "154 (210)",
    motorElectricSystem: 24,
    motorToxic: "Stage China IV",
    motorFuelTank: 362,

    smoothPlateModel: "DRV51-TVE, электрический",
    widthSmoothPlate: "2,55/ 5,10/ 8,80",
    freqRammingBeam: 1500,
    freqVibrator: 3000,
    transverseFracturePlate: "-2,5 / +4,5",

    parameterA: 2900,
    parameterB1: 3400,
    parameterB2: 2550,
    parameterB3: 1990,
    parameterC1: 320,
    parameterH1: 3900,
    parameterH2: 3100,
    parameterH3: 490,
    parameterL1: 6150,
    parameterL3: 2120,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/DRF25_80C_CN/drf25_80c_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/pavers/drawings/DRF25_80C_CN/drf25_80c_2.png",
    ],
  },
];
