import { useParams, Link } from "react-router-dom";
import { dataAsphaltRink } from "../../data/dataAsphaltRink";
import { useRef, useEffect } from "react";

import Styles from "./DetailPage.module.css";
import { NotFoundPage } from "../NotFoundPage/NotFoundPage";
import { BtnUp } from "../../components/UI/BtnUp/BtnUp";

export const AsphaltRinkDetailPage = () => {
  const refSection = useRef(null);
  const scrollSection = () => {
    refSection.current.scrollIntoView({ behavior: "smooth" });
  };

  const { title } = useParams();
  const item = dataAsphaltRink.find((item) => item.title === title);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!item) {
    return <NotFoundPage />;
  }
  return (
    <>
      <section className={Styles["short-description"]}>
        <h2 className={Styles["title__short-description"]}>{item.title}</h2>
        <div className={Styles["content-hero__short-description"]}>
          <img src={item.image} alt={item.title} />
          <div className={Styles["text__short-description"]}>
            <div className={Styles["short-start"]}>
              <h3 className={Styles["title__short-start"]}>
                Коротко о технике
              </h3>
              <button
                onClick={scrollSection}
                className={Styles["btn__short-start"]}
              >
                Полные характеристики
              </button>
            </div>
            <div className={Styles["weight__short-description"]}>
              <table className={Styles["table__ttx"]}>
                <tbody>
                  <tr>
                    <td>Максимальная рабочая масса, кг:</td>
                    <td className={Styles["td__ttx"]}>{item.maxWorkWight}</td>
                  </tr>
                  {item.maxWorkWightNoRopsKg && (
                    <tr>
                      <td>Рабочая масса (включая ROPS), кг:</td>
                      <td className={Styles["td__ttx"]}>
                        {item.maxWorkWightNoRopsKg}
                      </td>
                    </tr>
                  )}
                  {item.maxWorkWightRopsKg && (
                    <tr>
                      <td>Рабочая масса (включая кабину с ROPS), кг:</td>
                      <td className={Styles["td__ttx"]}>
                        {item.maxWorkWightRopsKg}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Масса переднего модуля, кг:</td>
                    <td className={Styles["td__ttx"]}>
                      {item.wightFirstModule}
                    </td>
                  </tr>
                  <tr>
                    <td>Масса заднего модуля, кг:</td>
                    <td className={Styles["td__ttx"]}>
                      {item.wightLastModule}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={Styles["btns__short-description"]}>
              <Link to={item.docs} className={Styles["btn__download-docs"]}>
                Скачать спецификацию
              </Link>
              <Link to="/contacts" className={Styles["btn__arrange"]}>
                Связаться с менеджером
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className={Styles["info"]}>
        <h2 className={Styles["title__info"]}>информация о технике</h2>
        <ul type="disc" className={Styles["text__info"]}>
          <li className={Styles["text__info_list"]}>{item.desc[0]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[1]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[2]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[3]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[4]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[5]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[6]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[7]}</li>
          {item.desc[8] && (
            <li className={Styles["text__info_list"]}>{item.desc[8]}</li>
          )}
          {item.desc[9] && (
            <li className={Styles["text__info_list"]}>{item.desc[9]}</li>
          )}
          {item.desc[10] && (
            <li className={Styles["text__info_list"]}>{item.desc[10]}</li>
          )}
        </ul>
      </section>

      <section
        ref={refSection}
        className={Styles["specifications"]}
        id="specifications"
      >
        <h2 className={Styles["title__specifications"]}>
          характеристики техники
        </h2>
        <div className={Styles["table__specifications"]}>
          <table>
            <tbody>
              <tr className={Styles["table__specifications_title"]}>
                <td>Масса</td>
              </tr>
              {item.wheelLoad && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Нагрузка на колесо, кг:</td>
                  <td>{item.wheelLoad}</td>
                </tr>
              )}
              <tr className={Styles["table__specifications_line"]}>
                <td>Максимальная рабочая масса, кг:</td>
                <td>{item.maxWorkWight}</td>
              </tr>
              {item.maxWorkWightNoRopsKg && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Рабочая масса (включая ROPS), кг:</td>
                  <td>{item.maxWorkWightNoRopsKg}</td>
                </tr>
              )}
              {item.maxWorkWightRopsKg && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Рабочая масса (включая кабину с ROPS), кг:</td>
                  <td>{item.maxWorkWightRopsKg}</td>
                </tr>
              )}
              <tr className={Styles["table__specifications_line"]}>
                <td>Масса переднего модуля, кг:</td>
                <td>{item.wightFirstModule}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Масса заднего модуля, кг:</td>
                <td>{item.wightLastModule}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Ходовая часть</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Диапазон скорости, км/час:</td>
                <td>{item.rangeSpeed}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Вертикальный угол поворота рулевого шарнира, °:</td>
                <td>{item.verticalAngleRotation}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Угол поворота, °:</td>
                <td>{item.angleRotation}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Теоретически преодолеваемый уклон, %:</td>
                <td>{item.theorySurmountSlope}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Уплотнение</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Статическая линейная нагрузка, кг/см:</td>
                <td>{item.staticLineLoad}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Амплитуда, высокая/низкая, мм:</td>
                <td>{item.amplitudeHighLow}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Частота вибрации при высокой/низкой амплитуде, Гц:</td>
                <td>{item.vibrationFreqHighLow}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Центробежная сила при выс./низ. Амплитуде, кН:</td>
                <td>{item.centrifugalForceHighLow}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Водяной бак, л:</td>
                <td>{item.waterTank}</td>
              </tr>
              {item.emulsionTank && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Бак для эмульсии, л:</td>
                  <td>{item.emulsionTank}</td>
                </tr>
              )}

              <tr className={Styles["table__specifications_title"]}>
                <td>Двигатель</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Производитель / модель:</td>
                <td>{item.motorModel}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Номинальная мощность, кВт (л.с.):</td>
                <td>{item.nominalPower}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Нормы токсичности:</td>
                <td>{item.motorToxic}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ёмкость топливного бака, л :</td>
                <td>{item.fuelTankCapacity}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className={Styles["gabarits"]}>
        <h2 className={Styles["title__gabarits"]}>габаритные размеры</h2>
        <div className={Styles["content__gabarits"]}>
          <div className={Styles["images__gabarits"]}>
            <img src={item.imagesParameters[0]} alt="фото габаритов 1" />
            <img src={item.imagesParameters[1]} alt="фото габаритов 2" />
          </div>
          <div className={Styles["table__gabarits"]}>
            <table>
              <tbody>
                {item.parameterA && (
                  <tr>
                    <td>A. Колесная база:</td>
                    <td>{item.parameterA} мм</td>
                  </tr>
                )}
                {item.parameterB && (
                  <tr>
                    <td>B. Ширина:</td>
                    <td>{item.parameterB} мм</td>
                  </tr>
                )}
                {item.parameterB1 && (
                  <tr>
                    <td>B1. Ширина переднего модуля:</td>
                    <td>{item.parameterB1} мм</td>
                  </tr>
                )}
                {item.parameterB2 && (
                  <tr>
                    <td>B2. Ширина (кабина):</td>
                    <td>{item.parameterB2} мм</td>
                  </tr>
                )}
                {item.parameterB3 && (
                  <tr>
                    <td>B3. Боковое смещение вальцов:</td>
                    <td>{item.parameterB3} мм</td>
                  </tr>
                )}
                {item.parameterC && (
                  <tr>
                    <td>C. Просвет над бордюрным камнем:</td>
                    <td>{item.parameterC} мм</td>
                  </tr>
                )}
                {item.parameterD && (
                  <tr>
                    <td>D. Диаметр вальца:</td>
                    <td>{item.parameterD} мм</td>
                  </tr>
                )}
                {item.parameterH && (
                  <tr>
                    <td>H1. Высота с ROPS/кабиной:</td>
                    <td>{item.parameterH} мм</td>
                  </tr>
                )}
                {item.parameterH1 && (
                  <tr>
                    <td>H1. Высота с ROPS/кабиной:</td>
                    <td>{item.parameterH1} мм</td>
                  </tr>
                )}
                {item.parameterH2 && (
                  <tr>
                    <td>H2. Высота без ROPS/кабины:</td>
                    <td>{item.parameterH2} мм</td>
                  </tr>
                )}
                {item.parameterK && (
                  <tr>
                    <td>K. Дорожный просвет:</td>
                    <td>{item.parameterK} мм</td>
                  </tr>
                )}
                {item.parameterK1 && (
                  <tr>
                    <td>K. Дорожный просвет:</td>
                    <td>{item.parameterK1} мм</td>
                  </tr>
                )}
                {item.parameterK2 && (
                  <tr>
                    <td>K. Дорожный просвет:</td>
                    <td>{item.parameterK2} мм</td>
                  </tr>
                )}
                {item.parameterL && (
                  <tr>
                    <td>L. Длина:</td>
                    <td>{item.parameterL} мм</td>
                  </tr>
                )}
                {item.parameterO && (
                  <tr>
                    <td>O. Боковое смещение:</td>
                    <td>{item.parameterO} мм</td>
                  </tr>
                )}
                {item.parameterO1 && (
                  <tr>
                    <td>O1. Вылет рамы, справа:</td>
                    <td>{item.parameterO1} мм</td>
                  </tr>
                )}
                {item.parameterO2 && (
                  <tr>
                    <td>O2. Вылет рамы, слева:</td>
                    <td>{item.parameterO2} мм</td>
                  </tr>
                )}
                {item.parameterR && (
                  <tr>
                    <td>R. Радиус поворота внешний:</td>
                    <td>{item.parameterR} мм</td>
                  </tr>
                )}
                {item.parameterR1 && (
                  <tr>
                    <td>R1. Радиус поворота внешний:</td>
                    <td>{item.parameterR1} мм</td>
                  </tr>
                )}
                {item.parameterR2 && (
                  <tr>
                    <td>R2. Радиус поворота внутренний:</td>
                    <td>{item.parameterR2} мм</td>
                  </tr>
                )}
                {item.parameterR3 && (
                  <tr>
                    <td>R3. Радиус поворота внешний (Смещаемый валец):</td>
                    <td>{item.parameterR3} мм</td>
                  </tr>
                )}
                {item.parameterR4 && (
                  <tr>
                    <td>R4. Радиус поворота внутренний (Смещаемый валец):</td>
                    <td>{item.parameterR4} мм</td>
                  </tr>
                )}
                {item.parameterS && (
                  <tr>
                    <td>S. Толщина обечайки вальца:</td>
                    <td>{item.parameterS} мм</td>
                  </tr>
                )}
                {item.parameterW && (
                  <tr>
                    <td>W. Ширина вальца:</td>
                    <td>{item.parameterW} мм</td>
                  </tr>
                )}
                {item.parameterW1 && (
                  <tr>
                    <td>W1. Ширина вальца:</td>
                    <td>{item.parameterW1} мм</td>
                  </tr>
                )}
                {item.parameterW2 && (
                  <tr>
                    <td>W2. Ширина колесного модуля:</td>
                    <td>{item.parameterW2} мм</td>
                  </tr>
                )}
                {item.parameterW3 && (
                  <tr>
                    <td>W3. Ширина колеса:</td>
                    <td>{item.parameterW3} мм</td>
                  </tr>
                )}
                {item.parameterW4 && (
                  <tr>
                    <td>W4. Межколесное расстояние:</td>
                    <td>{item.parameterW4} мм</td>
                  </tr>
                )}
                {item.parameterW5 && (
                  <tr>
                    <td>W5. Колесный интервал:</td>
                    <td>{item.parameterW5} мм</td>
                  </tr>
                )}
                {item.parameterAlfa && (
                  <tr>
                    <td>α. Угол поворота рулевого шарнира:</td>
                    <td>{item.parameterAlfa}°</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <BtnUp />
      </section>
    </>
  );
};
