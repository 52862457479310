import { Link } from "react-router-dom";
import Styles from "./CategoryCards.module.css";

export const CategoryCards = () => {
  return (
    <>
      <Link to="rinks" className={Styles["item__category"]}>
        <img
          src="./img/other/category_rinks.jpg"
          alt="фото катка главная страница"
        />
        <h3 className={Styles["name__category"]}>Катки</h3>
        <p className={Styles["text__category"]}>
          У DYNAPAC есть решение задач для уплотнения на любых объектах. Мы
          предлагаем полную линейку оборудования, начиная от малых катков для
          работ на тротуарах до самых тяжелых для работ на магистралях.
        </p>
      </Link>
      <Link to="pavers" className={Styles["item__category"]}>
        <img
          src="./img/other/category_pavers.jpg"
          alt="фото асфальтоукладчика главная страница"
        />
        <h3 className={Styles["name__category"]}>Асфальтоукладчики</h3>
        <p className={Styles["text__category"]}>
          Для достижения высокого качества укладки асфальта, требуется
          оборудование с эффективной выглаживающей плитой, равномерной подачей
          материала и мощным приводом, способное обеспечить надлежащую ровность,
          требуемый профиль и предварительное уплотнение покрытия. Все это
          гарантируют асфальтоукладчики DYNAPAC.
        </p>
      </Link>
      <Link to="overloads" className={Styles["item__category"]}>
        <img
          src="./img/other/category_overloaders.jpg"
          alt="фото перегружателя главная страница"
        />
        <h3 className={Styles["name__category"]}>Перегружатели</h3>
        <p className={Styles["text__category"]}>
          Мобильные перегружатели DYNAPAC предназначены для бесконтактной
          выгрузки асфальтобетонных смесей и других материалов из кузова
          самосвала в приемный бункер асфальтоукладчика. Высокопроизводительная
          транспортерная система способна переместить до 30 тонн материала менее
          чем за 35 секунд! Перегружатели DYNAPAC — единственные на рынке с
          габаритной шириной 2,55 метра!
        </p>
      </Link>
    </>
  );
};
