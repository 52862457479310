import { Link } from "react-router-dom";
import { dataGroundRink } from "../../data/dataGroundRink";
import Styles from "./GroundRinkPage.module.css";
import { BtnUp } from "../../components/UI/BtnUp/BtnUp";

export const GroundRinkPage = () => {
  return (
    <>
      <section className={Styles["catalog"]}>
        <h2 className={Styles["title__catalog"]}>грунтовые катки</h2>
        <div className={Styles["items__catalog"]}>
          {dataGroundRink.map((item) => (
            <Link
              to={`/rinks/ground/${item.title}`}
              className={Styles["item__catalog"]}
              key={item.id}
            >
              <img src={item.image} alt={item.title} />
              <h4 className={Styles["title__item"]}>{item.title}</h4>
              <div className={Styles["ttx__item"]}>
                <div className={Styles["weight__ttx"]}>
                  <p>Рабочая масса, т: </p>
                  <p>{item.maxWorkWightRopsTons}</p>
                </div>
                <div className={Styles["load__ttx"]}>
                  <p>Статическая линейная нагрузка, кг/см: </p>
                  <p>{item.staticLineLoad}</p>
                </div>
              </div>
              <Link
                to={`/rinks/ground/${item.title}`}
                className={Styles["item__btn"]}
              >
                подробнее
              </Link>
            </Link>
          ))}
        </div>
        <BtnUp />
      </section>
    </>
  );
};
