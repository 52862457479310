import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import Styles from "./MapCompany.module.css";

export const MapCompany = () => {
  return (
    <>
      <YMaps>
        <div className={Styles["map__contact"]}>
          <Map
            width="100%"
            height="100%"
            defaultState={{
              center: [56.852676, 53.2069],
              zoom: 5,
              controls: ["zoomControl", "fullscreenControl"],
            }}
            modules={["control.ZoomControl", "control.FullscreenControl"]}
          >
            <Placemark
              modules={["geoObject.addon.balloon"]}
              geometry={[55.914292, 37.417778]}
              properties={{
                balloonContentBody:
                  "Московская область, г. Химки, Транспортный проезд, д. 2 +7(495) 775-25-85",
              }}
            />
            <Placemark
              modules={["geoObject.addon.balloon"]}
              geometry={[59.846364, 30.436]}
              properties={{
                balloonContentBody:
                  "г. Санкт-Петербург, пр-т Девятого Января, д. 3А, корп. 6, оф. 6 +7 (812) 335-06-26",
              }}
            />
            <Placemark
              modules={["geoObject.addon.balloon"]}
              geometry={[56.821957, 60.681503]}
              properties={{
                balloonContentBody:
                  "г. Екатеринбург, ул. Чистопольская, д. 13, оф. 214 +7 (343) 312-34-55",
              }}
            />
            <Placemark
              modules={["geoObject.addon.balloon"]}
              geometry={[55.321579, 86.053053]}
              pr
              properties={{
                balloonContentBody:
                  "г. Кемерово, ул. 1-я Камышинская, д. 47А +7 (3842) 46-44-36",
              }}
            />
          </Map>
        </div>
      </YMaps>
    </>
  );
};
