export const dataRink = [
  {
    id: 1,
    title: "CA1500D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca1500d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca1500d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 8500,
    maxWorkWightRopsTons: "7,2",
    maxWorkWightRopsKg: 7200,
    wightFirstModule: 3600,
    wightLastModule: 3600,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 55,

    staticLineLoad: 21,
    amplitudeHighLow: "1,8 / 0,80",
    vibrationFreqHighLow: "32 / 36",
    centrifugalForceHighLow: "133 / 72",

    motorModel: "Cummins QSB3.3",
    nominalPower: "110 л.с. (82 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 190,

    parameterA: 2690,
    parameterB1: 1866,
    parameterB2: 1700,
    parameterD: 1219,
    parameterH: 2730,
    parameterK1: 320,
    parameterK2: 350,
    parameterL: 5280,
    parameterO1: 95,
    parameterO2: 95,
    parameterR1: 4800,
    parameterR2: 3100,
    parameterS: 22,
    parameterW: 1676,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca1500d/ca1500d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca1500d/ca1500d_2.png",
    ],
  },
  {
    id: 2,
    title: "CA2500D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca2500d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca2500d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 11900,
    maxWorkWightRopsTons: "10,3",
    maxWorkWightRopsKg: 10300,
    wightFirstModule: 5600,
    wightLastModule: 4700,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 51,

    staticLineLoad: 26,
    amplitudeHighLow: "1,8 / 0,90",
    vibrationFreqHighLow: "33 / 34",
    centrifugalForceHighLow: "250 / 130",

    motorModel: "Cummins QSB4.5",
    nominalPower: "130 л.с. (97 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 255,

    parameterA: 2990,
    parameterB1: 2300,
    parameterB2: 2130,
    parameterD: 1500,
    parameterH: 2870,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 5990,
    parameterO1: 105,
    parameterO2: 105,
    parameterR1: 5450,
    parameterR2: 3300,
    parameterS: 25,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca2500d/ca2500d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca2500d/ca2500d_2.png",
    ],
  },
  {
    id: 3,
    title: "CA2800D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca2800d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca2800d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 13800,
    maxWorkWightRopsTons: "12,2",
    maxWorkWightRopsKg: 12200,
    wightFirstModule: 7600,
    wightLastModule: 4600,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 55,

    staticLineLoad: 36,
    amplitudeHighLow: "1,8 / 0,90",
    vibrationFreqHighLow: "33 / 34",
    centrifugalForceHighLow: "250 / 130",

    motorModel: "Cummins QSB4.5",
    nominalPower: "130 л.с. (97 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 2990,
    parameterB1: 2300,
    parameterB2: 2130,
    parameterD: 1500,
    parameterH: 2870,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 5990,
    parameterO1: 85,
    parameterO2: 85,
    parameterR1: 5600,
    parameterR2: 3300,
    parameterS: 25,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca2800d/ca2800d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca2800d/ca2800d_2.png",
    ],
  },
  {
    id: 4,
    title: "CA4000D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca4000d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca4000d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 14900,
    maxWorkWightRopsTons: "13,3",
    maxWorkWightRopsKg: 13300,
    wightFirstModule: 8700,
    wightLastModule: 4600,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 55,

    staticLineLoad: 41,
    amplitudeHighLow: "2,0 / 0,80",
    vibrationFreqHighLow: "30 / 30",
    centrifugalForceHighLow: "270 / 120",

    motorModel: "Cummins QSB4.5",
    nominalPower: "160 л.с. (119 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 2990,
    parameterB1: 2300,
    parameterB2: 2130,
    parameterD: 1520,
    parameterH: 2880,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 5990,
    parameterO1: 85,
    parameterO2: 85,
    parameterR1: 5600,
    parameterR2: 3300,
    parameterS: 35,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca4000d/ca4000d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca4000d/ca4000d_2.png",
    ],
  },
  {
    id: 5,
    title: "CA5000D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca5000d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca5000d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Deutz с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 18700,
    maxWorkWightRopsTons: "16,2",
    maxWorkWightRopsKg: 16200,
    wightFirstModule: 10700,
    wightLastModule: 5500,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 45,

    staticLineLoad: 50,
    amplitudeHighLow: "2,1 / 0,80",
    vibrationFreqHighLow: "29 / 30",
    centrifugalForceHighLow: "330 / 140",

    motorModel: "Deutz TCD2012L06",
    nominalPower: "174 л.с. (128 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 3100,
    parameterB1: 2340,
    parameterB2: 2130,
    parameterD: 1536,
    parameterH: 2890,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 6240,
    parameterO1: 105,
    parameterO2: 105,
    parameterR1: 5800,
    parameterR2: 3300,
    parameterS: 43,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca5000d/ca5000d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca5000d/ca5000d_2.png",
    ],
  },
  {
    id: 6,
    title: "CA5500D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca5500d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca5500d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Deutz с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 18600,
    maxWorkWightRopsTons: "18,4",
    maxWorkWightRopsKg: 18400,
    wightFirstModule: 11850,
    wightLastModule: 6500,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 45,

    staticLineLoad: 56,
    amplitudeHighLow: "2,1 / 0,80",
    vibrationFreqHighLow: "29 / 30",
    centrifugalForceHighLow: "330 / 150",

    motorModel: "Deutz TCD2012L06",
    nominalPower: "174 л.с. (128 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 3100,
    parameterB1: 2340,
    parameterB2: 2130,
    parameterD: 1546,
    parameterH: 2890,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 6240,
    parameterO1: 105,
    parameterO2: 105,
    parameterR1: 5800,
    parameterR2: 3300,
    parameterS: 48,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca5500d/ca5500d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca5500d/ca5500d_2.png",
    ],
  },
  {
    id: 7,
    title: "CA6000D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca6000d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca6000d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Deutz с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 19800,
    maxWorkWightRopsTons: "19,5",
    maxWorkWightRopsKg: 19500,
    wightFirstModule: 12850,
    wightLastModule: 6700,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 45,

    staticLineLoad: 60,
    amplitudeHighLow: "2,1 / 0,80",
    vibrationFreqHighLow: "29 / 30",
    centrifugalForceHighLow: "360 / 150",

    motorModel: "Deutz TCD2012L06",
    nominalPower: "204 л.с. (150 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 3100,
    parameterB1: 2340,
    parameterB2: 2130,
    parameterD: 1546,
    parameterH: 2890,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 6240,
    parameterO1: 105,
    parameterO2: 105,
    parameterR1: 5800,
    parameterR2: 3300,
    parameterS: 48,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca6000d/ca6000d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca6000d/ca6000d_2.png",
    ],
  },
  {
    id: 8,
    title: "CA6500D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca6500d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca6500d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Deutz с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 21000,
    maxWorkWightRopsTons: "20,9",
    maxWorkWightRopsKg: 20900,
    wightFirstModule: 13950,
    wightLastModule: 6950,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 45,

    staticLineLoad: 65,
    amplitudeHighLow: "2,1 / 0,80",
    vibrationFreqHighLow: "29 / 30",
    centrifugalForceHighLow: "360 / 150",

    motorModel: "Deutz TCD2012L06",
    nominalPower: "204 л.с. (150 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 3100,
    parameterB1: 2380,
    parameterB2: 2130,
    parameterD: 1546,
    parameterH: 2890,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 6240,
    parameterO1: 125,
    parameterO2: 125,
    parameterR1: 5800,
    parameterR2: 3300,
    parameterS: 48,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca6500d/ca6500d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca6500d/ca6500d_2.png",
    ],
  },
  {
    id: 9,
    title: "CA35D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca35d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca35d_en.pdf",
    desc: [
      "Каток с наилучшим соотношением цена-качество;",
      "Мощный гидростатический привод на валец и колеса – уверенная тяга в любых ситуациях;",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером;",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектаци;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Продуманная циркуляция охлаждающего воздуха обеспечивает низкий уровень шума;",
    ],

    maxWorkWight: 12400,
    maxWorkWightRopsTons: "12,3",
    maxWorkWightRopsKg: 12300,
    wightFirstModule: 7400,
    wightLastModule: 4900,

    rangeSpeed: "0 - 6,5",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 43,

    staticLineLoad: 35,
    amplitudeHighLow: "1,7 / 0,80",
    vibrationFreqHighLow: "33 / 33",
    centrifugalForceHighLow: "250 / 123",

    motorModel: "Cummins QSF3.8",
    nominalPower: "130 л.с. (97 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 280,

    parameterA: 2990,
    parameterB1: 2276,
    parameterB2: 2130,
    parameterD: 1510,
    parameterH: 2890,
    parameterK1: 460,
    parameterK2: 460,
    parameterL: 5560,
    parameterO1: 73,
    parameterO2: 73,
    parameterR1: 5660,
    parameterR2: 3245,
    parameterS: 30,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca35d/ca35d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca35d/ca35d_2.png",
    ],
  },
  {
    id: 10,
    title: "CA65D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca65d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca65d_en.pdf",
    desc: [
      "Каток с наилучшим соотношением цена-качество;",
      "Мощный гидростатический привод на валец и колеса – уверенная тяга в любых ситуациях;",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером;",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектаци;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Продуманная циркуляция охлаждающего воздуха обеспечивает низкий уровень шума;",
    ],

    maxWorkWight: 21800,
    maxWorkWightRopsTons: "20,3",
    maxWorkWightRopsKg: 20300,
    wightFirstModule: 13800,
    wightLastModule: 6500,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 44,

    staticLineLoad: 64,
    amplitudeHighLow: "1,8 / 1,10",
    vibrationFreqHighLow: "30 / 31",
    centrifugalForceHighLow: "339 / 231",

    motorModel: "Cummins QSB5.9",
    nominalPower: "154 л.с. (207 кВт)\n при 2 400 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 320,

    parameterA: 3100,
    parameterB1: 2360,
    parameterB2: 2130,
    parameterD: 1573,
    parameterH1: 2900,
    parameterH2: 2145,
    parameterK1: 470,
    parameterK2: 500,
    parameterL: 6236,
    parameterO1: 130,
    parameterO2: 100,
    parameterR1: 5630,
    parameterR2: 3500,
    parameterS: 50,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca65d/ca65d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca65d/ca65d_2.png",
    ],
  },
  {
    id: 11,
    title: "CC800",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc800.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_cc800_ru.pdf",
    desc: [
      "Привод вибрации на оба вальца с системой автоматического управления;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивная панель управления с датчиком уровня топлива и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Удобный доступ к сервисным компонентам.",
    ],

    maxWorkWight: 1665,
    maxWorkWightRopsTons: "1,6",
    maxWorkWightNoRopsKg: 1575,
    wightFirstModule: 740,
    wightLastModule: 835,

    rangeSpeed: "0 - 9",
    verticalAngleRotation: "±6",
    angleRotation: "±34",
    theorySurmountSlope: 40,

    staticLineLoad: "9,3 / 10,4",
    amplitudeHighLow: "0,4",
    vibrationFreqHighLow: 70,
    centrifugalForceHighLow: 17,
    waterTank: 110,

    motorModel: "Kubota – D1105-E4B",
    nominalPower: "24 л.с. (18 кВт)\n при 2 800 об/мин",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 23,

    parameterA: 1350,
    parameterB: 870,
    parameterC: 455,
    parameterD: 588,
    parameterH1: 2300,
    parameterH2: 1585,
    parameterK: 250,
    parameterL1: 2040,
    parameterL2: 2095,
    parameterO1: 35,
    parameterO2: 35,
    parameterR1: 2650,
    parameterS: 14,
    parameterW: 800,
    parameterAlfa: "±34",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc800/cc800_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc800/cc800_2.png",
    ],
  },
  {
    id: 12,
    title: "CC900",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc900.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_cc900_ru.pdf",
    desc: [
      "Привод вибрации на оба вальца с системой автоматического управления;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивная панель управления с датчиком уровня топлива и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Удобный доступ к сервисным компонентам.",
    ],

    maxWorkWight: 1665,
    maxWorkWightRopsTons: "1,6",
    maxWorkWightNoRopsKg: 1600,
    wightFirstModule: 750,
    wightLastModule: 850,

    rangeSpeed: "0 - 9",
    verticalAngleRotation: "±6",
    angleRotation: "±34",
    theorySurmountSlope: 40,

    staticLineLoad: "8,3 / 9,4",
    amplitudeHighLow: "0,4",
    vibrationFreqHighLow: 70,
    centrifugalForceHighLow: 17,
    waterTank: 110,

    motorModel: "Kubota – D1105-E4B",
    nominalPower: "24 л.с. (18 кВт)\n при 2 800 об/мин",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 23,

    parameterA: 1350,
    parameterC: 465,
    parameterD: 584,
    parameterH1: 2300,
    parameterH2: 1585,
    parameterK: 255,
    parameterL1: 2040,
    parameterL2: 2095,
    parameterO1: 35,
    parameterO2: 35,
    parameterR1: 2810,
    parameterS: 12,
    parameterW: 900,
    parameterAlfa: "±34",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc900/cc900_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc900/cc900_2.png",
    ],
  },
  {
    id: 13,
    title: "CC1100-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc1100_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_cc1100_vi_ru.pdf",
    desc: [
      "Двух режимная вибрация на обоих вальцах с автоматическим режимом управления;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Боковое механическое смещение вальцев до 50мм уже в базовой комплектации;",
      "Режим высокочастотной вибрации 66 Гц для уплотнения тонких слоев без риска дробления материала;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивная панель управления с дисплеем, кнопочным модулем и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью кромок;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Возможность установки формирователя кромок и распределителя каменной крошки;",
      "Удобный доступ к сервисным компонентам.",
    ],

    maxWorkWight: 3200,
    maxWorkWightRopsTons: "2,4",
    maxWorkWightNoRopsKg: 2402,
    wightFirstModule: 1144,
    wightLastModule: 1258,

    rangeSpeed: "0 - 10",
    verticalAngleRotation: "±10",
    angleRotation: "±30",
    theorySurmountSlope: 46,

    staticLineLoad: "10,7 / 11,8",
    amplitudeHighLow: "0,5",
    vibrationFreqHighLow: "66 / 61",
    centrifugalForceHighLow: "29 / 25",
    waterTank: 205,

    motorModel: "Kubota – D1703-M",
    nominalPower: "35 л.с. (26 кВт)\n при 2 800 об/мин",
    motorToxic: "Tier4i / IIIA",
    fuelTankCapacity: 45,

    parameterA: 1700,
    parameterB: 1210,
    parameterC: 554,
    parameterD: 700,
    parameterH1: 2542,
    parameterH2: 1744,
    parameterK: 180,
    parameterL: 2400,
    parameterO1: 70,
    parameterO2: 70,
    parameterR1: 3710,
    parameterR2: 2640,
    parameterS: 12,
    parameterW: 1070,
    parameterAlfa: "±30",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1100_vi/cc1100_vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1100_vi/cc1100_vi_2.png",
    ],
  },
  {
    id: 14,
    title: "CC1100C-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc1100c_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_cc1100c_vi_ru.pdf",
    desc: [
      "Двух режимная вибрация на обоих вальцах с автоматическим режимом управления;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Боковое механическое смещение вальцев до 50мм уже в базовой комплектации;",
      "Режим высокочастотной вибрации 66 Гц для уплотнения тонких слоев без риска дробления материала;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивная панель управления с дисплеем, кнопочным модулем и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью кромок;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Возможность установки формирователя кромок и распределителя каменной крошки;",
      "Удобный доступ к сервисным компонентам.",
    ],

    wheelLoad: 268,
    maxWorkWight: 3000,
    maxWorkWightRopsTons: "2,2",
    maxWorkWightNoRopsKg: 2215,
    wightFirstModule: 1145,
    wightLastModule: 1070,

    rangeSpeed: "0 - 10",
    verticalAngleRotation: "±10",
    angleRotation: "±30",
    theorySurmountSlope: 71,

    staticLineLoad: "10,7",
    amplitudeHighLow: "0,5",
    vibrationFreqHighLow: "66 / 61",
    centrifugalForceHighLow: "29 / 25",
    waterTank: 185,
    emulsionTank: 20,

    motorModel: "Kubota – D1703-M",
    nominalPower: "35 л.с. (26 кВт)\n при 2 800 об/мин",
    motorToxic: "Tier4i / IIIA",
    fuelTankCapacity: 45,

    parameterA: 1700,
    parameterB: 1210,
    parameterC: 554,
    parameterD: 700,
    parameterH1: 2542,
    parameterH2: 1744,
    parameterK: 180,
    parameterL: 2400,
    parameterO1: 70,
    parameterO2: 70,
    parameterR1: 3710,
    parameterR2: 2640,
    parameterS: 12,
    parameterW1: 1070,
    parameterW2: 1040,
    parameterW3: 233,
    parameterAlfa: "±30",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1100c_vi/cc1100c_vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1100c_vi/cc1100c_vi_2.png",
    ],
  },
  {
    id: 15,
    title: "CC1200-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc1200_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_cc1200_vi_ru.pdf",
    desc: [
      "Двух режимная вибрация на обоих вальцах с автоматическим режимом управления;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Боковое механическое смещение вальцев до 50мм уже в базовой комплектации;",
      "Режим высокочастотной вибрации 66 Гц для уплотнения тонких слоев без риска дробления материала;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивная панель управления с дисплеем, кнопочным модулем и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью кромок;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Возможность установки формирователя кромок и распределителя каменной крошки;",
      "Удобный доступ к сервисным компонентам.",
    ],

    maxWorkWight: 3400,
    maxWorkWightRopsTons: "2,6",
    maxWorkWightNoRopsKg: 2600,
    wightFirstModule: 1230,
    wightLastModule: 1370,

    rangeSpeed: "0 - 10",
    verticalAngleRotation: "±10",
    angleRotation: "±30",
    theorySurmountSlope: 42,

    staticLineLoad: "10,3 / 11,4",
    amplitudeHighLow: "0,5",
    vibrationFreqHighLow: "66 / 61",
    centrifugalForceHighLow: "34 / 29",
    waterTank: 205,

    motorModel: "Kubota – D1703-M",
    nominalPower: "35 л.с. (26 кВт)\n при 2 800 об/мин",
    motorToxic: "Tier4i / IIIA",
    fuelTankCapacity: 45,

    parameterA: 1700,
    parameterB: 1340,
    parameterC: 554,
    parameterD: 702,
    parameterH1: 2542,
    parameterH2: 1744,
    parameterK: 180,
    parameterL: 2400,
    parameterO1: 70,
    parameterO2: 70,
    parameterR1: 3770,
    parameterR2: 2570,
    parameterS: 13,
    parameterW: 1200,
    parameterAlfa: "±30",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1200_vi/cc1200_vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1200_vi/cc1200_vi_2.png",
    ],
  },
  {
    id: 16,
    title: "CC1200C-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc1200c_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_cc1200c_vi_ru.pdf",
    desc: [
      "Двух режимная вибрация на обоих вальцах с автоматическим режимом управления;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Боковое механическое смещение вальцев до 50мм уже в базовой комплектации;",
      "Режим высокочастотной вибрации 66 Гц для уплотнения тонких слоев без риска дробления материала;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивная панель управления с дисплеем, кнопочным модулем и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью кромок;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Возможность установки формирователя кромок и распределителя каменной крошки;",
      "Удобный доступ к сервисным компонентам.",
    ],

    wheelLoad: 271,
    maxWorkWight: 3100,
    maxWorkWightRopsTons: "2,3",
    maxWorkWightNoRopsKg: 2315,
    wightFirstModule: 1230,
    wightLastModule: 1085,

    rangeSpeed: "0 - 10",
    verticalAngleRotation: "±10",
    angleRotation: "±30",
    theorySurmountSlope: 65,

    staticLineLoad: "10,3",
    amplitudeHighLow: "0,5",
    vibrationFreqHighLow: "66 / 61",
    centrifugalForceHighLow: "34 / 29",
    waterTank: 185,
    emulsionTank: 20,

    motorModel: "Kubota – D1703-M",
    nominalPower: "35 л.с. (26 кВт)\n при 2 800 об/мин",
    motorToxic: "Tier4i / IIIA",
    fuelTankCapacity: 45,

    parameterA: 1700,
    parameterB: 1340,
    parameterC: 554,
    parameterD: 702,
    parameterH1: 2542,
    parameterH2: 1744,
    parameterK: 180,
    parameterL: 2400,
    parameterO1: 70,
    parameterO2: 70,
    parameterR1: 3770,
    parameterR2: 2570,
    parameterS: 13,
    parameterW1: 1200,
    parameterW2: 1140,
    parameterW3: 244,
    parameterAlfa: "±30",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1200c_vi/cc1200c_vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1200c_vi/cc1200c_vi_2.png",
    ],
  },
  {
    id: 17,
    title: "CC1300-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc1300_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_cc1300_vi_ru.pdf",
    desc: [
      "Двух режимная вибрация на обоих вальцах с автоматическим режимом управления;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Боковое механическое смещение вальцев до 50мм уже в базовой комплектации;",
      "Оптимизированные параметры вибрации для уплотнения тонких слоев без риска дробления материала;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивная панель управления с дисплеем, кнопочным модулем и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью кромок;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Удобный доступ к сервисным компонентам.",
    ],

    maxWorkWight: 4800,
    maxWorkWightRopsTons: "3,9",
    maxWorkWightNoRopsKg: 3900,
    wightFirstModule: 1855,
    wightLastModule: 2045,

    rangeSpeed: "0 - 9",
    verticalAngleRotation: "±10",
    angleRotation: "±30",
    theorySurmountSlope: 42,

    staticLineLoad: "14,3 / 15,7",
    amplitudeHighLow: "0,5",
    vibrationFreqHighLow: "54 / 49",
    centrifugalForceHighLow: "38 / 31",
    waterTank: 298,

    motorModel: "Kubota V2203-M",
    nominalPower: "48 л.с. (35 кВт)\n при 2 700 об/мин",
    motorToxic: "Tier3/IIIA",
    fuelTankCapacity: 60,

    parameterA: 1970,
    parameterB: 1425,
    parameterB3: 50,
    parameterC: 690,
    parameterD: 880,
    parameterH1: 2855,
    parameterH2: 2025,
    parameterK: 190,
    parameterL: 2850,
    parameterO1: 62.5,
    parameterO2: 62.5,
    parameterR1: 4330,
    parameterR2: 3030,
    parameterS: 16,
    parameterW: 1300,
    parameterAlfa: "±30",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1300_vi/cc1300_vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1300_vi/cc1300_vi_2.png",
    ],
  },
  {
    id: 18,
    title: "CC1300C-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc1300c_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_cc1300c_vi_ru.pdf",
    desc: [
      "Двух режимная вибрация на обоих вальцах с автоматическим режимом управления;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Боковое механическое смещение вальцев до 50мм уже в базовой комплектации;",
      "Оптимизированные параметры вибрации для уплотнения тонких слоев без риска дробления материала;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивная панель управления с дисплеем, кнопочным модулем и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью кромок;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Удобный доступ к сервисным компонентам.",
    ],

    wheelLoad: 464,
    maxWorkWight: 4600,
    maxWorkWightRopsTons: "3,7",
    maxWorkWightNoRopsKg: 3700,
    wightFirstModule: 1855,
    wightLastModule: 1845,

    rangeSpeed: "0 - 9",
    verticalAngleRotation: "±10",
    angleRotation: "±30",
    theorySurmountSlope: 41,

    staticLineLoad: "14,3",
    amplitudeHighLow: "0,5",
    vibrationFreqHighLow: "54 / 49",
    centrifugalForceHighLow: "38 / 31",
    waterTank: 298,
    emulsionTank: 25,

    motorModel: "Kubota V2203-M",
    nominalPower: "48 л.с. (35 кВт)\n при 2 700 об/мин",
    motorToxic: "Tier3/IIIA",
    fuelTankCapacity: 60,

    parameterA: 1970,
    parameterB: 1425,
    parameterB3: 50,
    parameterC: 690,
    parameterD: 880,
    parameterH1: 2855,
    parameterH2: 2025,
    parameterK: 190,
    parameterL: 2830,
    parameterO1: 62.5,
    parameterO2: 62.5,
    parameterR1: 4330,
    parameterR2: 3030,
    parameterS: 16,
    parameterW1: 1300,
    parameterW2: 1282,
    parameterW3: 285,
    parameterAlfa: "±30",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1300c_vi/cc1300c_vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1300c_vi/cc1300c_vi_2.png",
    ],
  },
  {
    id: 19,
    title: "CC1400-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc1400_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc1400_vi_ru.pdf",
    desc: [
      "Двухрежимная вибрация с автоматическим режимом управления для идеального результата;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Боковое смещение модулей до 50мм в базовой комплектации;",
      "Оптимизированные параметры вибрации для уплотнения тонких слоев без риска дробления материала;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивно понятная панель управления с дисплеем, кнопочным модулем и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью кромок;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Удобный доступ к сервисным компонентам;",
    ],

    maxWorkWight: 5130,
    maxWorkWightRopsTons: "4,3",
    maxWorkWightNoRopsKg: 4300,
    wightFirstModule: 2080,
    wightLastModule: 2220,

    rangeSpeed: "0 - 9",
    verticalAngleRotation: "±10",
    angleRotation: "±30",
    theorySurmountSlope: 37,

    staticLineLoad: "15,1 / 16,1",
    amplitudeHighLow: "0,5",
    vibrationFreqHighLow: "54 / 49",
    centrifugalForceHighLow: "43 / 35",
    waterTank: 298,

    motorModel: "Kubota V2203-M",
    nominalPower: "48 л.с. (35 кВт)\n при 2 700 об/мин",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 60,

    parameterA: 1970,
    parameterB: 1505,
    parameterB3: 50,
    parameterC: 690,
    parameterD: 882,
    parameterH1: 2855,
    parameterH2: 2025,
    parameterK: 190,
    parameterL: 2850,
    parameterO1: 62.5,
    parameterO2: 62.5,
    parameterR1: 4370,
    parameterR2: 2990,
    parameterS: 17,
    parameterW: 1380,
    parameterAlfa: "±30",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1400vi/cc1400vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1400vi/cc1400vi_2.png",
    ],
  },
  {
    id: 20,
    title: "CC1400C-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc1400c_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc1400c_vi_ru.pdf",
    desc: [
      "Двухрежимная вибрация с автоматическим режимом управления для идеального результата;",
      "Гидростатический привод на оба вальца обеспечивает плавное и точное управление;",
      "Боковое смещение модулей до 50мм в базовой комплектации;",
      "Оптимизированные параметры вибрации для уплотнения тонких слоев без риска дробления материала;",
      "Надежная система орошения с автоматическим управлением и многоуровневой системой фильтрации;",
      "Экономичный мощный двигатель с водяным охлаждением;",
      "Интуитивно понятная панель управления с дисплеем, кнопочным модулем и сервисными индикаторами;",
      "Просторная, виброизолированная платформа c отличной обзорностью кромок;",
      "Модульная конструкция и широкая унификация сменных и изнашиваемых частей;",
      "Удобный доступ к сервисным компонентам;",
    ],

    wheelLoad: 455,
    maxWorkWight: 4800,
    maxWorkWightRopsTons: "3,9",
    maxWorkWightNoRopsKg: 3900,
    wightFirstModule: 2080,
    wightLastModule: 1820,

    rangeSpeed: "0 - 9",
    verticalAngleRotation: "±10",
    angleRotation: "±30",
    theorySurmountSlope: 39,

    staticLineLoad: "15,1",
    amplitudeHighLow: "0,5",
    vibrationFreqHighLow: "54 / 49",
    centrifugalForceHighLow: "43 / 35",
    waterTank: 298,
    emulsionTank: 25,

    motorModel: "Kubota V2203-M",
    nominalPower: "48 л.с. (35 кВт)\n при 2 700 об/мин",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 60,

    parameterA: 1970,
    parameterB: 1505,
    parameterB3: 50,
    parameterC: 690,
    parameterD: 882,
    parameterH1: 2855,
    parameterH2: 2025,
    parameterK: 190,
    parameterL: 2830,
    parameterO1: 62.5,
    parameterO2: 62.5,
    parameterR1: 4370,
    parameterR2: 2990,
    parameterS: 17,
    parameterW1: 1380,
    parameterW2: 1282,
    parameterW3: 285,
    parameterAlfa: "±30",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1400c_vi/cc1400cvi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc1400c_vi/cc1400cvi_2.png",
    ],
  },
  {
    id: 21,
    title: "CC2200",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc2200.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc2200_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (48Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнени;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 9000,
    maxWorkWightRopsTons: "7,8",
    maxWorkWightRopsKg: 7800,
    wightFirstModule: 3900,
    wightLastModule: 3900,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 42,

    staticLineLoad: "26,0 / 26,0",
    amplitudeHighLow: "0,70 / 0,30",
    vibrationFreqHighLow: "48 / 67",
    centrifugalForceHighLow: "78 / 67",
    waterTank: 750,

    motorModel: "Cummins - QSB 3.3",
    nominalPower: "99 л.с. (74 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 130,

    parameterA: 3340,
    parameterB: 1620,
    parameterC: 715,
    parameterD: 1150,
    parameterH1: 2990,
    parameterK: 270,
    parameterL: 4490,
    parameterO: 335,
    parameterR1: 6570,
    parameterR2: 5070,
    parameterS: 20,
    parameterW: 1500,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc2200/cc2200_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc2200/cc2200_2.png",
    ],
  },
  {
    id: 22,
    title: "CC2200C",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc2200c.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc2200c_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (48Гц);",
      "Оптимальная развесовка гарантирует идеальное качеством уплотнения, в том числе задним пневмоколесным модулем;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    wheelLoad: 925,
    maxWorkWight: 8200,
    maxWorkWightRopsTons: "7,6",
    maxWorkWightRopsKg: 7600,
    wightFirstModule: 3900,
    wightLastModule: 3700,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 49,

    staticLineLoad: "26,0",
    amplitudeHighLow: "0,70 / 0,30",
    vibrationFreqHighLow: "48 / 67",
    centrifugalForceHighLow: "78 / 67",
    waterTank: 750,
    emulsionTank: 78,

    motorModel: "Cummins - QSB 3.3",
    nominalPower: "99 л.с. (74 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 130,

    parameterA: 3340,
    parameterB: 1620,
    parameterC: 715,
    parameterD: 1150,
    parameterH1: 2990,
    parameterK: 270,
    parameterL: 4490,
    parameterO: 335,
    parameterR1: 6570,
    parameterR2: 5070,
    parameterR3: 4890,
    parameterR4: 3390,
    parameterS: 20,
    parameterW1: 1500,
    parameterW2: 1454,
    parameterW3: 285,
    parameterW4: 441,
    parameterW5: 75,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc2200c/cc2200c_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc2200c/cc2200c_2.png",
    ],
  },
  {
    id: 23,
    title: "CC3800",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc3800.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc3800_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (48Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 10700,
    maxWorkWightRopsTons: "9,6",
    maxWorkWightRopsKg: 9600,
    wightFirstModule: 4800,
    wightLastModule: 4800,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 33,

    staticLineLoad: "27,7 / 27,7",
    amplitudeHighLow: "0,70 / 0,30",
    vibrationFreqHighLow: "48 / 67",
    centrifugalForceHighLow: "90 / 75",
    waterTank: 750,

    motorModel: "Cummins - QSB 3.3",
    nominalPower: "99 л.с. (74 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 130,

    parameterA: 3340,
    parameterB: 1870,
    parameterC: 715,
    parameterD: 1150,
    parameterH1: 2990,
    parameterK: 270,
    parameterL: 4490,
    parameterR: 6690,
    parameterS: 17,
    parameterW: 1730,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc3800/cc3800_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc3800/cc3800_2.png",
    ],
  },
  {
    id: 24,
    title: "CC3800H",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc3800h.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc3800h_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (48Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 10700,
    maxWorkWightRopsTons: "10,0",
    maxWorkWightRopsKg: 10000,
    wightFirstModule: 5000,
    wightLastModule: 5000,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 31,

    staticLineLoad: "28,9 / 28,9",
    amplitudeHighLow: "0,60 / 0,30",
    vibrationFreqHighLow: "48 / 67",
    centrifugalForceHighLow: "90 / 75",
    waterTank: 750,

    motorModel: "Cummins - QSB 3.3",
    nominalPower: "99 л.с. (74 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 130,

    parameterA: 3340,
    parameterB: 2020,
    parameterC: 715,
    parameterD: 1150,
    parameterH1: 2990,
    parameterK: 270,
    parameterL: 4490,
    parameterR: 6690,
    parameterS: 20,
    parameterW: 1730,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc3800h/cc3800h_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc3800h/cc3800h_2.png",
    ],
  },
  {
    id: 25,
    title: "CC4200-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc4200_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc4200_vi_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (51Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 13150,
    maxWorkWightRopsTons: "10,1",
    maxWorkWightRopsKg: 10160,
    wightFirstModule: 5080,
    wightLastModule: 5080,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 40,

    staticLineLoad: "30,2 / 30,2",
    amplitudeHighLow: "0,80 / 0,30",
    vibrationFreqHighLow: "51 / 67",
    centrifugalForceHighLow: "128 / 84",
    waterTank: 680,

    motorModel: "Cummins - QSB 3.8",
    nominalPower: "140 л.с. (104 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 180,

    parameterA: 3760,
    parameterB1: 1910,
    parameterB2: 2140,
    parameterC: 815,
    parameterD: 1300,
    parameterH1: 2990,
    parameterK: 310,
    parameterL: 5060,
    parameterO: 520,
    parameterR1: 7270,
    parameterR2: 5590,
    parameterR3: 5460,
    parameterR4: 3780,
    parameterS: 20,
    parameterW: 1680,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc4200vi/cc4200vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc4200vi/cc4200vi_2.png",
    ],
  },
  {
    id: 26,
    title: "CC4200C-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc4200c_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc4200c_vi_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (51Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    wheelLoad: 1100,
    maxWorkWight: 11000,
    maxWorkWightRopsTons: "9,6",
    maxWorkWightRopsKg: 9560,
    wightFirstModule: 5160,
    wightLastModule: 4400,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 41,

    staticLineLoad: "29,8",
    amplitudeHighLow: "0,80 / 0,30",
    vibrationFreqHighLow: "51 / 67",
    centrifugalForceHighLow: "128 / 84",
    waterTank: 680,
    emulsionTank: 200,

    motorModel: "Cummins - QSF 3.8",
    nominalPower: "140 л.с. (104 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 180,

    parameterA: 3720,
    parameterB1: 1910,
    parameterB2: 2140,
    parameterC: 815,
    parameterD: 1300,
    parameterH1: 2990,
    parameterK: 310,
    parameterL: 5001,
    parameterO: 520,
    parameterR1: 7270,
    parameterR2: 5590,
    parameterR3: 5460,
    parameterR4: 3780,
    parameterS: 20,
    parameterW1: 1680,
    parameterW2: 1610,
    parameterW3: 322,
    parameterW4: 464,
    parameterW5: 128,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc4200c_vi/cc4200cvi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc4200c_vi/cc4200cvi_2.png",
    ],
  },
  {
    id: 27,
    title: "CC5200-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc5200_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc5200_vi_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (51Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 14890,
    maxWorkWightRopsTons: "11,9",
    maxWorkWightRopsKg: 11870,
    wightFirstModule: 5935,
    wightLastModule: 5935,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 34,

    staticLineLoad: "30,3 / 30,3",
    amplitudeHighLow: "0,80 / 0,30",
    vibrationFreqHighLow: "51 / 67",
    centrifugalForceHighLow: "144 / 93",
    waterTank: 850,

    motorModel: "Cummins - QSB 3.8",
    nominalPower: "140 л.с. (104 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 180,

    parameterA: 3760,
    parameterB1: 2180,
    parameterB2: 2410,
    parameterC: 815,
    parameterD: 1300,
    parameterH1: 2990,
    parameterK: 310,
    parameterL: 5060,
    parameterO: 520,
    parameterR1: 7410,
    parameterR2: 5460,
    parameterR3: 5580,
    parameterR4: 3630,
    parameterS: 20,
    parameterW: 1950,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc5200vi/cc5200vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc5200vi/cc5200vi_2.png",
    ],
  },
  {
    id: 28,
    title: "CC5200C-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc5200c_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc5200c_vi_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (51Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    wheelLoad: 1100,
    maxWorkWight: 11700,
    maxWorkWightRopsTons: "10,4",
    maxWorkWightRopsKg: 10380,
    wightFirstModule: 5970,
    wightLastModule: 4410,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 35,

    staticLineLoad: "30,3",
    amplitudeHighLow: "0,80 / 0,30",
    vibrationFreqHighLow: "51 / 67",
    centrifugalForceHighLow: "144 / 93",
    waterTank: 850,
    emulsionTank: 200,

    motorModel: "Cummins - QSF 3.8",
    nominalPower: "140 л.с. (104 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 180,

    parameterA: 3720,
    parameterB1: 2180,
    parameterB2: 2410,
    parameterC: 815,
    parameterD: 1300,
    parameterH1: 2990,
    parameterK: 310,
    parameterL: 5001,
    parameterO: 520,
    parameterR1: 7410,
    parameterR2: 5460,
    parameterR3: 5580,
    parameterR4: 3630,
    parameterS: 20,
    parameterW1: 1950,
    parameterW2: 1610,
    parameterW3: 322,
    parameterW4: 464,
    parameterW5: 128,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc5200c_vi/cc5200cvi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc5200c_vi/cc5200cvi_2.png",
    ],
  },
  {
    id: 29,
    title: "CC6200-VI",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/rinks/cc6200_vi.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc6200_vi_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (51Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 13950,
    maxWorkWightRopsTons: "12,5",
    maxWorkWightRopsKg: 12470,
    wightFirstModule: 6235,
    wightLastModule: 6235,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 32,

    staticLineLoad: "29,1 / 29,1",
    amplitudeHighLow: "0,80 / 0,30",
    vibrationFreqHighLow: "51 / 67",
    centrifugalForceHighLow: "157 / 103",
    waterTank: 850,

    motorModel: "Cummins - QSB 3.8",
    nominalPower: "140 л.с. (104 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 180,

    parameterA: 3760,
    parameterB1: 2360,
    parameterB2: 2500,
    parameterC: 815,
    parameterD: 1300,
    parameterH1: 2990,
    parameterK: 310,
    parameterL: 5060,
    parameterR1: 7500,
    parameterR2: 5370,
    parameterS: 20,
    parameterW: 2130,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc6200vi/cc6200vi_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc6200vi/cc6200vi_2.png",
    ],
  },
  {
    id: 30,
    title: "CC4200",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc4200.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc4200_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (48Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 11650,
    maxWorkWightRopsTons: "10,4",
    maxWorkWightRopsKg: 10400,
    wightFirstModule: 5200,
    wightLastModule: 5200,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 45,

    staticLineLoad: "30,1 / 30,1",
    amplitudeHighLow: "0,80 / 0,30",
    vibrationFreqHighLow: "51 / 67",
    centrifugalForceHighLow: "139 / 92",
    waterTank: 900,

    motorModel: "Cummins - QSB 4.5",
    nominalPower: "125 л.с. (93 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 240,

    parameterA: 3690,
    parameterB: 1878,
    parameterC: 865,
    parameterD: 1300,
    parameterH1: 2990,
    parameterH2: 2275,
    parameterK: 310,
    parameterL: 4990,
    parameterR: 5960,
    parameterS: 18,
    parameterW: 1730,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc4200/cc4200_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc4200/cc4200_2.png",
    ],
  },
  {
    id: 31,
    title: "CC5200",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc5200.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc5200_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (48Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 11650,
    maxWorkWightRopsTons: "11,5",
    maxWorkWightRopsKg: 11500,
    wightFirstModule: 5750,
    wightLastModule: 5750,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 40,

    staticLineLoad: "29,5 / 29,5",
    amplitudeHighLow: "0,80 / 0,30",
    vibrationFreqHighLow: "51 / 67",
    centrifugalForceHighLow: "154 / 101",
    waterTank: 900,

    motorModel: "Cummins - QSB 4.5",
    nominalPower: "125 л.с. (93 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 240,

    parameterA: 3690,
    parameterB: 2138,
    parameterC: 865,
    parameterD: 1300,
    parameterH1: 2990,
    parameterH2: 2275,
    parameterK: 310,
    parameterL: 4990,
    parameterR: 6070,
    parameterS: 18,
    parameterW: 1950,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc5200/cc5200_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc5200/cc5200_2.png",
    ],
  },
  {
    id: 32,
    title: "CC6200",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/cc6200.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/cc6200_ru.pdf",
    desc: [
      "Электронный «плавный старт и останов» снижает риск образования брака поверхности уплотняемого слоя;",
      "Технологическая универсальность сочетает возможность уплотнения тонкослойных покрытий с помощью высокочастотной вибрации (67Гц), и толстых слоев с применением высокоамплитудной вибрации (48Гц);",
      "Идеальная развесовка между передним и задним модулями – 100% производительность с идеальным качеством уплотнения;",
      "Автоматическое управление вибрацией и системой орошения – удобство и комфорт в течение всей рабочей смены;",
      "Полный пакет транспортного освещения и светодиодных рабочих фар в базовой комплектации;",
      "Уникальная асимметричная виброизолированная кабина обеспечивает панорамный обзор и превосходную видимость зоны уплотнения и кромок вальцов;",
      "Единый поворотно-сдвигаемый модуль управления. Органы управления и дисплей следуют за всеми перемещениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Силовая установка находится в заднем модуле – низкий уровень шума в кабине и отличная сервисная доступность;",
    ],

    maxWorkWight: 13600,
    maxWorkWightRopsTons: "12,2",
    maxWorkWightRopsKg: 12200,
    wightFirstModule: 6100,
    wightLastModule: 6100,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±7",
    angleRotation: "±32",
    theorySurmountSlope: 38,

    staticLineLoad: "28,6 / 28,6",
    amplitudeHighLow: "0,80 / 0,30",
    vibrationFreqHighLow: "51 / 67",
    centrifugalForceHighLow: "166 / 106",
    waterTank: 900,

    motorModel: "Cummins - QSB 4.5",
    nominalPower: "152 л.с. (113 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 240,

    parameterA: 3690,
    parameterB: 2290,
    parameterC: 865,
    parameterD: 1300,
    parameterH1: 2990,
    parameterH2: 2275,
    parameterK: 310,
    parameterL: 4990,
    parameterR: 6160,
    parameterS: 18,
    parameterW: 2130,
    parameterAlfa: "±32",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc6200/cc6200_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/cc6200/cc6200_2.png",
    ],
  },
];
