import Styles from "./Homepage.module.css";
import { MainScreen } from "../../components/MainScreen/MainScreen";
import { AboutSection } from "../../components/AboutSection/AboutSection";
import { CategoryCards } from "../../components/CategorySection/CategoryCards";
import { useRef, useEffect } from "react";

export const Homepage = () => {
  const categorySectionRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={Styles["main-screen__section"]}>
        <MainScreen categorySectionRef={categorySectionRef} />
      </section>

      <section className={Styles["about"]}>
        <h2 className={Styles["title__about"]}>о компании</h2>
        <AboutSection />
      </section>

      <section ref={categorySectionRef} className={Styles["category"]}>
        <h2 className={Styles["title__category"]}>каталог</h2>
        <div className={Styles["items__category"]}>
          <CategoryCards />
        </div>
      </section>
    </>
  );
};
