import { useParams, Link } from "react-router-dom";
import { dataOverload } from "../../data/dataOverload";
import { useRef, useEffect } from "react";

import Styles from "./DetailPage.module.css";
import { NotFoundPage } from "../NotFoundPage/NotFoundPage";
import { BtnUp } from "../../components/UI/BtnUp/BtnUp";

export const OverloadDetailPage = () => {
  const refSection = useRef(null);
  const scrollSection = () => {
    refSection.current.scrollIntoView({ behavior: "smooth" });
  };

  const { title } = useParams();
  const item = dataOverload.find((item) => item.title === title);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!item) {
    return <NotFoundPage />;
  }

  const imageStyle = {
    width: 250,
    height: 370,
  };

  return (
    <>
      <section className={Styles["short-description"]}>
        <h2 className={Styles["title__short-description"]}>{item.title}</h2>
        <div className={Styles["content-hero__short-description"]}>
          <img src={item.image} alt={item.title} />
          <div className={Styles["text__short-description"]}>
            <div className={Styles["short-start"]}>
              <h3 className={Styles["title__short-start"]}>
                Коротко о технике
              </h3>
              <button
                onClick={scrollSection}
                className={Styles["btn__short-start"]}
              >
                Полные характеристики
              </button>
            </div>
            <div className={Styles["weight__short-description"]}>
              <table className={Styles["table__ttx"]}>
                <tbody>
                  <tr>
                    <td>Производительность т/ч:</td>
                    <td className={Styles["td__ttx"]}>{item.layCapTheory}</td>
                  </tr>
                  <tr>
                    <td>Эксплутационная масса, т:</td>
                    <td className={Styles["td__ttx"]}>
                      {item.operatingWeight}
                    </td>
                  </tr>
                  <tr>
                    <td>Рабочая скорость, м/мин:</td>
                    <td className={Styles["td__ttx"]}>{item.workSpeed}</td>
                  </tr>
                  <tr>
                    <td>Емкость бункера, т:</td>
                    <td className={Styles["td__ttx"]}>
                      {item.bunkerContainer}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={Styles["btns__short-description"]}>
              <Link to={item.docs} className={Styles["btn__download-docs"]}>
                Скачать спецификацию
              </Link>
              <Link to="/contacts" className={Styles["btn__arrange"]}>
                Связаться с менеджером
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className={Styles["info"]}>
        <h2 className={Styles["title__info"]}>информация о технике</h2>
        <ul type="disc" className={Styles["text__info"]}>
          <li className={Styles["text__info_list"]}>{item.desc[0]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[1]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[2]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[3]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[4]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[5]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[6]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[7]}</li>
          {item.desc[8] && (
            <li className={Styles["text__info_list"]}>{item.desc[8]}</li>
          )}
        </ul>
      </section>

      <section
        ref={refSection}
        className={Styles["specifications"]}
        id="specifications"
      >
        <h2 className={Styles["title__specifications"]}>
          характеристики техники
        </h2>
        <div className={Styles["table__specifications"]}>
          <table>
            <tr className={Styles["table__specifications_title"]}>
              <td>Система управления</td>
            </tr>
            <tbody>
              <tr className={Styles["table__specifications_line"]}>
                <td>Наименование системы управления:</td>
                <td>{item.systemControl}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Производительность</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Производительность укладки (теор.), т/ч :</td>
                <td>{item.layCapTheory}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Масса</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Эксплутационная масса, т:</td>
                <td>{item.operatingWeight}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Ходовая часть</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Рабочая скорость, м/мин :</td>
                <td>{item.workSpeed}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Транспортная скорость, км/ч :</td>
                <td>{item.transportSpeed}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Угол въезда, °:</td>
                <td>{item.entranceAngle}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Шасси</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>База (линия контакта гусеницы с основанием), мм:</td>
                <td>{item.chassisBase}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ширина гусеницы, мм:</td>
                <td>{item.trackWidth}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Бункер</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Управление, т:</td>
                <td>{item.bunkerManage}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ёмкость, мм:</td>
                <td>{item.bunkerContainer}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ширина внутренняя, мм:</td>
                <td>{item.bunkerInnerWidth}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Высота разгрузки, по центру/по краям, мм:</td>
                <td>{item.bunkerHeightUnload}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Транспортер</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Управление:</td>
                <td>{item.conveyorManage}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ширина, мм:</td>
                <td>{item.conveyorWidth}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Двигатель</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Управление:</td>
                <td>{item.motorManage}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Производитель/модель:</td>
                <td>{item.motorModel}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Номинальная мощность, кВт (л.с.):</td>
                <td>{item.motorMinimalPower}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Электрическая система, В:</td>
                <td>{item.motorElectricSystem}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Нормы токсичности:</td>
                <td>{item.motorToxic}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Емкость топливного бака, л:</td>
                <td>{item.motorFuelTank}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className={Styles["gabarits"]}>
        <h2 className={Styles["title__gabarits"]}>габаритные размеры</h2>
        <div className={Styles["content__gabarits"]}>
          <div className={Styles["images__gabarits"]}>
            <img src={item.imagesParameters[0]} alt="фото габаритов 1" />
            <img
              src={item.imagesParameters[1]}
              style={imageStyle}
              alt="фото габаритов 2"
            />
            {item.imagesParameters[2] && (
              <img src={item.imagesParameters[2]} alt="фото габаритов 3" />
            )}
            {item.imagesParameters[3] && (
              <img src={item.imagesParameters[3]} alt="фото габаритов 4" />
            )}
            {item.imagesParameters[4] && (
              <img src={item.imagesParameters[4]} alt="фото габаритов 5" />
            )}
          </div>
          <div className={Styles["table__gabarits"]}>
            <table>
              <tbody>
                <tr>
                  <td>Опорная длина гусеницы:</td>
                  <td>{item.trackRefLength} мм</td>
                </tr>
                <tr>
                  <td>Ширина максимальная:</td>
                  <td>{item.widthMax} мм</td>
                </tr>
                <tr>
                  <td>Ширина транспортная:</td>
                  <td>{item.widthTransport} мм</td>
                </tr>
                <tr>
                  <td>Колея гусениц:</td>
                  <td>{item.trackTrack} мм</td>
                </tr>
                <tr>
                  <td>Ширина гусеницы:</td>
                  <td>{item.widthTrack} мм</td>
                </tr>
                <tr>
                  <td>Высота рабочая:</td>
                  <td>{item.heightWork} мм</td>
                </tr>
                <tr>
                  <td>Высота транспортная:</td>
                  <td>{item.heightTransport} мм</td>
                </tr>
                <tr>
                  <td>Высота разгрузочная:</td>
                  <td>{item.heightUnloading} мм</td>
                </tr>
                <tr>
                  <td>Высота максимальная (с поднятой платформой):</td>
                  <td>{item.heightMaxRaisedPlatform} мм</td>
                </tr>
                <tr>
                  <td>Длина общая:</td>
                  <td>{item.lengthAll} мм</td>
                </tr>
                <tr>
                  <td>Длина бункера:</td>
                  <td>{item.lengthBunkers} мм</td>
                </tr>
                <tr>
                  <td>Высота выгрузки, минимальная:</td>
                  <td>{item.heightUnloadingMin} мм</td>
                </tr>
                <tr>
                  <td>Высота выгрузки, максимальная:</td>
                  <td>{item.heightUnloadingMax} мм</td>
                </tr>
                {item.heightUnloadingRotaryCon && (
                  <tr className={Styles["table__specifications_line"]}>
                    <td>
                      Высота выгрузки (min-max) с поворотным транспортером:
                    </td>
                    <td>{item.heightUnloadingRotaryCon}</td>
                  </tr>
                )}
                {item.heightUnloadingNoRotaryCon && (
                  <tr className={Styles["table__specifications_line"]}>
                    <td>
                      Высота выгрузки (min-max) со снятым поворотным
                      транспортером :
                    </td>
                    <td>{item.heightUnloadingNoRotaryCon}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <BtnUp />
      </section>
    </>
  );
};
