import { NavLink } from "react-router-dom";
import Styles from "./Header.module.css";
import { useState, useEffect, useRef } from "react";

export const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isDropMenu, setIsDropMenu] = useState(false);
  const dropMenuRef = useRef(null);

  const closeMenu = () => {
    setIsOpenMenu(false);
  };

  const dropMenuHandle = () => {
    setIsDropMenu(!isDropMenu);
  };

  const handleDropdownItemClick = () => {
    dropMenuHandle();
    closeMenu();
  };

  const handleClickOutside = (e) => {
    if (dropMenuRef.current && !dropMenuRef.current.contains(e.target)) {
      setIsDropMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className={Styles["header"]}>
        <NavLink to="/" className={Styles["logo"]}>
          <img
            className={Styles["logo"]}
            src="/img/logo/logo.svg"
            alt="логотип dst"
          />
        </NavLink>
        <nav className={`${Styles["nav"]} ${isOpenMenu ? Styles.open : ""}`}>
          <ul className={Styles["items-text__nav"]}>
            <li className={Styles["item__nav"]}>
              <NavLink
                to="rinks"
                className={Styles["item__nav_ref"]}
                onClick={closeMenu}
              >
                Катки
              </NavLink>
              <span
                className={Styles["dropdown__toggle"]}
                onClick={dropMenuHandle}
              ></span>
              {isDropMenu && (
                <ul className={Styles["dropdown__menu"]} ref={dropMenuRef}>
                  <li className={Styles["item__nav_drop"]}>
                    <NavLink
                      to="rinks/ground"
                      className={Styles["dropdown__item"]}
                      onClick={handleDropdownItemClick}
                    >
                      Грунтовые
                    </NavLink>
                  </li>
                  <li className={Styles["item__nav_drop"]}>
                    <NavLink
                      to="rinks/asphalt"
                      className={Styles["dropdown__item"]}
                      onClick={handleDropdownItemClick}
                    >
                      Асфальтовые
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className={Styles["item__nav"]}>
              <NavLink
                to="pavers"
                className={Styles["item__nav_ref"]}
                onClick={closeMenu}
              >
                Асфальтоукладчики
              </NavLink>
            </li>
            <li className={Styles["item__nav"]}>
              <NavLink
                to="overloads"
                className={Styles["item__nav_ref"]}
                onClick={closeMenu}
              >
                Перегружатели
              </NavLink>
            </li>
            <li className={Styles["item__nav"]}>
              <NavLink
                to="contacts"
                className={Styles["item__nav_ref"]}
                onClick={closeMenu}
              >
                Контакты
              </NavLink>
            </li>
            <li className={Styles["item__nav"]}>
              <a href="tel:+7 (916) 830-40-73">+7 (916) 830-40-73</a>
            </li>
          </ul>
        </nav>
        <div
          className={`${Styles["burger"]} ${isOpenMenu ? Styles.active : ""}`}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          <span></span>
        </div>
      </header>
    </>
  );
};
