export const dataGroundRink = [
  {
    id: 1,
    title: "CA1500D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca1500d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca1500d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 8500,
    maxWorkWightRopsTons: "7,2",
    maxWorkWightRopsKg: 7200,
    wightFirstModule: 3600,
    wightLastModule: 3600,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 55,

    staticLineLoad: 21,
    amplitudeHighLow: "1,8 / 0,80",
    vibrationFreqHighLow: "32 / 36",
    centrifugalForceHighLow: "133 / 72",

    motorModel: "Cummins QSB3.3",
    nominalPower: "110 л.с. (82 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 190,

    parameterA: 2690,
    parameterB1: 1866,
    parameterB2: 1700,
    parameterD: 1219,
    parameterH: 2730,
    parameterK1: 320,
    parameterK2: 350,
    parameterL: 5280,
    parameterO1: 95,
    parameterO2: 95,
    parameterR1: 4800,
    parameterR2: 3100,
    parameterS: 22,
    parameterW: 1676,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca1500d/ca1500d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca1500d/ca1500d_2.png",
    ],
  },
  {
    id: 2,
    title: "CA2500D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca2500d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca2500d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 11900,
    maxWorkWightRopsTons: "10,3",
    maxWorkWightRopsKg: 10300,
    wightFirstModule: 5600,
    wightLastModule: 4700,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 51,

    staticLineLoad: 26,
    amplitudeHighLow: "1,8 / 0,90",
    vibrationFreqHighLow: "33 / 34",
    centrifugalForceHighLow: "250 / 130",

    motorModel: "Cummins QSB4.5",
    nominalPower: "130 л.с. (97 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 255,

    parameterA: 2990,
    parameterB1: 2300,
    parameterB2: 2130,
    parameterD: 1500,
    parameterH: 2870,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 5990,
    parameterO1: 105,
    parameterO2: 105,
    parameterR1: 5450,
    parameterR2: 3300,
    parameterS: 25,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca2500d/ca2500d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca2500d/ca2500d_2.png",
    ],
  },
  {
    id: 3,
    title: "CA2800D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca2800d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca2800d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 13800,
    maxWorkWightRopsTons: "12,2",
    maxWorkWightRopsKg: 12200,
    wightFirstModule: 7600,
    wightLastModule: 4600,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 55,

    staticLineLoad: 36,
    amplitudeHighLow: "1,8 / 0,90",
    vibrationFreqHighLow: "33 / 34",
    centrifugalForceHighLow: "250 / 130",

    motorModel: "Cummins QSB4.5",
    nominalPower: "130 л.с. (97 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 2990,
    parameterB1: 2300,
    parameterB2: 2130,
    parameterD: 1500,
    parameterH: 2870,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 5990,
    parameterO1: 85,
    parameterO2: 85,
    parameterR1: 5600,
    parameterR2: 3300,
    parameterS: 25,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca2800d/ca2800d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca2800d/ca2800d_2.png",
    ],
  },
  {
    id: 4,
    title: "CA4000D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca4000d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca4000d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 14900,
    maxWorkWightRopsTons: "13,3",
    maxWorkWightRopsKg: 13300,
    wightFirstModule: 8700,
    wightLastModule: 4600,

    rangeSpeed: "0 - 12",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 55,

    staticLineLoad: 41,
    amplitudeHighLow: "2,0 / 0,80",
    vibrationFreqHighLow: "30 / 30",
    centrifugalForceHighLow: "270 / 120",

    motorModel: "Cummins QSB4.5",
    nominalPower: "160 л.с. (119 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 2990,
    parameterB1: 2300,
    parameterB2: 2130,
    parameterD: 1520,
    parameterH: 2880,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 5990,
    parameterO1: 85,
    parameterO2: 85,
    parameterR1: 5600,
    parameterR2: 3300,
    parameterS: 35,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca4000d/ca4000d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca4000d/ca4000d_2.png",
    ],
  },
  {
    id: 5,
    title: "CA5000D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca5000d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca5000d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Deutz с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 18700,
    maxWorkWightRopsTons: "16,2",
    maxWorkWightRopsKg: 16200,
    wightFirstModule: 10700,
    wightLastModule: 5500,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 45,

    staticLineLoad: 50,
    amplitudeHighLow: "2,1 / 0,80",
    vibrationFreqHighLow: "29 / 30",
    centrifugalForceHighLow: "330 / 140",

    motorModel: "Deutz TCD2012L06",
    nominalPower: "174 л.с. (128 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 3100,
    parameterB1: 2340,
    parameterB2: 2130,
    parameterD: 1536,
    parameterH: 2890,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 6240,
    parameterO1: 105,
    parameterO2: 105,
    parameterR1: 5800,
    parameterR2: 3300,
    parameterS: 43,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca5000d/ca5000d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca5000d/ca5000d_2.png",
    ],
  },
  {
    id: 6,
    title: "CA5500D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca5500d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca5500d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность.;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Deutz с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 18600,
    maxWorkWightRopsTons: "18,4",
    maxWorkWightRopsKg: 18400,
    wightFirstModule: 11850,
    wightLastModule: 6500,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 45,

    staticLineLoad: 56,
    amplitudeHighLow: "2,1 / 0,80",
    vibrationFreqHighLow: "29 / 30",
    centrifugalForceHighLow: "330 / 150",

    motorModel: "Deutz TCD2012L06",
    nominalPower: "174 л.с. (128 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 3100,
    parameterB1: 2340,
    parameterB2: 2130,
    parameterD: 1546,
    parameterH: 2890,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 6240,
    parameterO1: 105,
    parameterO2: 105,
    parameterR1: 5800,
    parameterR2: 3300,
    parameterS: 48,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca5500d/ca5500d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca5500d/ca5500d_2.png",
    ],
  },
  {
    id: 7,
    title: "CA6000D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca6000d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca6000d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Deutz с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 19800,
    maxWorkWightRopsTons: "19,5",
    maxWorkWightRopsKg: 19500,
    wightFirstModule: 12850,
    wightLastModule: 6700,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 45,

    staticLineLoad: 60,
    amplitudeHighLow: "2,1 / 0,80",
    vibrationFreqHighLow: "29 / 30",
    centrifugalForceHighLow: "360 / 150",

    motorModel: "Deutz TCD2012L06",
    nominalPower: "204 л.с. (150 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 3100,
    parameterB1: 2340,
    parameterB2: 2130,
    parameterD: 1546,
    parameterH: 2890,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 6240,
    parameterO1: 105,
    parameterO2: 105,
    parameterR1: 5800,
    parameterR2: 3300,
    parameterS: 48,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca6000d/ca6000d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca6000d/ca6000d_2.png",
    ],
  },
  {
    id: 8,
    title: "CA6500D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca6500d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca6500d_ru.pdf",
    desc: [
      "Революционная компоновка с поперечным расположением двигателя – идеальная развесовка и непревзойденная сервисная доступность;",
      "Электронная система контроля привода Anti-Spin последнего поколения и самоблокирующийся дифференциал заднего моста (зависит от комплектации);",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Deutz с непосредственным впрыском топлива и интеркулером и режимом Eco-Mode (зависит от комплектации);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Единый поворотный модуль управления. Органы управления и дисплей следуют за всеми движениями оператора внутри кабины;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Верхняя компоновка радиаторного блока, продуманная циркуляция охлаждающего воздуха обеспечивают один из самых низких уровней шума в классе;",
    ],

    maxWorkWight: 21000,
    maxWorkWightRopsTons: "20,9",
    maxWorkWightRopsKg: 20900,
    wightFirstModule: 13950,
    wightLastModule: 6950,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 45,

    staticLineLoad: 65,
    amplitudeHighLow: "2,1 / 0,80",
    vibrationFreqHighLow: "29 / 30",
    centrifugalForceHighLow: "360 / 150",

    motorModel: "Deutz TCD2012L06",
    nominalPower: "204 л.с. (150 кВт)\n при 2 200 об/мин;\n ISO 14396",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 272,

    parameterA: 3100,
    parameterB1: 2380,
    parameterB2: 2130,
    parameterD: 1546,
    parameterH: 2890,
    parameterK1: 450,
    parameterK2: 450,
    parameterL: 6240,
    parameterO1: 125,
    parameterO2: 125,
    parameterR1: 5800,
    parameterR2: 3300,
    parameterS: 48,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca6500d/ca6500d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca6500d/ca6500d_2.png",
    ],
  },
  {
    id: 9,
    title: "CA35D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca35d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca35d_en.pdf",
    desc: [
      "Каток с наилучшим соотношением цена-качество;",
      "Мощный гидростатический привод на валец и колеса – уверенная тяга в любых ситуациях;",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером;",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектаци;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Продуманная циркуляция охлаждающего воздуха обеспечивает низкий уровень шума;",
    ],

    maxWorkWight: 12400,
    maxWorkWightRopsTons: "12,3",
    maxWorkWightRopsKg: 12300,
    wightFirstModule: 7400,
    wightLastModule: 4900,

    rangeSpeed: "0 - 6,5",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 43,

    staticLineLoad: 35,
    amplitudeHighLow: "1,7 / 0,80",
    vibrationFreqHighLow: "33 / 33",
    centrifugalForceHighLow: "250 / 123",

    motorModel: "Cummins QSF3.8",
    nominalPower: "130 л.с. (97 кВт)\n при 2 200 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 280,

    parameterA: 2990,
    parameterB1: 2276,
    parameterB2: 2130,
    parameterD: 1510,
    parameterH: 2890,
    parameterK1: 460,
    parameterK2: 460,
    parameterL: 5560,
    parameterO1: 73,
    parameterO2: 73,
    parameterR1: 5660,
    parameterR2: 3245,
    parameterS: 30,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca35d/ca35d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca35d/ca35d_2.png",
    ],
  },
  {
    id: 10,
    title: "CA65D",
    image: "https://storage.yandexcloud.net/dst-storage/img/rinks/ca65d.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/rinks/dynapac_ca65d_en.pdf",
    desc: [
      "Каток с наилучшим соотношением цена-качество;",
      "Мощный гидростатический привод на валец и колеса – уверенная тяга в любых ситуациях;",
      "Проверенная временем на нескольких поколениях грунтовых катков система двух-амплитудной вибрации;",
      "Возможность быстрой установки кулачкового бандажа для уплотнения глинистых грунтов;",
      "Экономичный турбодизельный двигатель Cummins с непосредственным впрыском топлива и интеркулером;",
      "Полный пакет светодиодного рабочего освещения в базовой комплектации;",
      "Просторная комфортабельная кабина с панорамным остеклением и встроенным силовым каркасом защиты при опрокидывании (ROPS);",
      "Полный пакет светодиодного рабочего освещения в базовой комплектаци;",
      "Эргономичная панель управления с ЖК-дисплеем. Все процессы – под контролем;",
      "Продуманная циркуляция охлаждающего воздуха обеспечивает низкий уровень шума;",
    ],

    maxWorkWight: 21800,
    maxWorkWightRopsTons: "20,3",
    maxWorkWightRopsKg: 20300,
    wightFirstModule: 13800,
    wightLastModule: 6500,

    rangeSpeed: "0 - 11",
    verticalAngleRotation: "±9",
    angleRotation: "±38",
    theorySurmountSlope: 44,

    staticLineLoad: 64,
    amplitudeHighLow: "1,8 / 1,10",
    vibrationFreqHighLow: "30 / 31",
    centrifugalForceHighLow: "339 / 231",

    motorModel: "Cummins QSB5.9",
    nominalPower: "154 л.с. (207 кВт)\n при 2 400 об/мин;\n SAE J1995",
    motorToxic: "Tier3 / IIIA",
    fuelTankCapacity: 320,

    parameterA: 3100,
    parameterB1: 2360,
    parameterB2: 2130,
    parameterD: 1573,
    parameterH1: 2900,
    parameterH2: 2145,
    parameterK1: 470,
    parameterK2: 500,
    parameterL: 6236,
    parameterO1: 130,
    parameterO2: 100,
    parameterR1: 5630,
    parameterR2: 3500,
    parameterS: 50,
    parameterW: 2130,
    parameterAlfa: "±38",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca65d/ca65d_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/rinks/drawings/ca65d/ca65d_2.png",
    ],
  },
];
