import Styles from "./AboutSection.module.css";

export const AboutSection = () => {
  return (
    <>
      <div className={Styles["content__about"]}>
        <div className={Styles["text-content__about"]}>
          <p>
            ООО «ДорСтрой Техника» является официальным дилером производителя
            дорожно-строительной техники Dynapac на территории Российской
            Федерации. Компания Dynapac производит широкую линейку
            асфальтоукладчиков, асфальтовых и грунтовых катков, перегружателей и
            легкого уплотняющего оборудования. Дорожно-строительная техника
            Dynapac зарекомендовала себя как надежное и эффективное
            оборудование. Наша техника соответствует наивысшим стандартам
            качества и способна удовлетворить самые строгие требования наших
            клиентов. Dynapac по праву является одним из лидеров на мировом
            рынке производителей дорожно-строительной техники.
          </p>
          <p>
            Главным приоритетом ООО «ДорСтрой Техника» является предоставление
            высококачественной продукции и сервисного обслуживания, которые
            способствуют росту производительности и повышению эффективности
            работы наших заказчиков.
          </p>
          <p>Мы помогаем строить качественные дороги.</p>
        </div>
        <img
          className={Styles["img__about"]}
          src="./img/other/about__img.jpg"
          alt="о компании картинка дст"
        />
      </div>
    </>
  );
};
