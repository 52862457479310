import { Link } from "react-router-dom";
import { dataAsphaltRink } from "../../data/dataAsphaltRink";
import Styles from "./AsphaltRinkPage.module.css";
import { BtnUp } from "../../components/UI/BtnUp/BtnUp";

export const AsphaltRinkPage = () => {
  return (
    <>
      <section className={Styles["catalog"]}>
        <h2 className={Styles["title__catalog"]}>асфальтовые катки</h2>
        <div className={Styles["items__catalog"]}>
          {dataAsphaltRink.map((item) => (
            <Link
              to={`/rinks/asphalt/${item.title}`}
              className={Styles["item__catalog"]}
              key={item.id}
            >
              <img src={item.image} alt={item.title} />
              <h4 className={Styles["title__item"]}>{item.title}</h4>
              <div className={Styles["ttx__item"]}>
                <div className={Styles["weight__ttx"]}>
                  <p>Рабочая масса, т: </p>
                  <p>{item.maxWorkWightRopsTons}</p>
                </div>
                <div className={Styles["load__ttx"]}>
                  <p>Статическая линейная нагрузка, кг/см: </p>
                  <p>{item.staticLineLoad}</p>
                </div>
              </div>
              <Link
                to={`/rinks/asphalt/${item.title}`}
                className={Styles["item__btn"]}
              >
                подробнее
              </Link>
            </Link>
          ))}
        </div>
        <BtnUp />
      </section>
    </>
  );
};
