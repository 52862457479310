import { Link } from "react-router-dom";
import Styles from "./NotFoundPage.module.css";

export const NotFoundPage = () => {
  return (
    <section className={Styles["no_page__section"]}>
      <div className={Styles["content__404"]}>
        <h1 className={Styles["no_page__code"]}>404</h1>
        <h2 className={Styles["no_page__title"]}>Такой страницы нет </h2>
        <Link to="/" className={Styles["btn__home"]}>
          На главную
        </Link>
      </div>
    </section>
  );
};
