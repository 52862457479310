import { useParams, Link } from "react-router-dom";
import { dataPaver } from "../../data/dataPaver";
import { useRef, useEffect } from "react";

import Styles from "./DetailPage.module.css";
import { NotFoundPage } from "../NotFoundPage/NotFoundPage";
import { BtnUp } from "../../components/UI/BtnUp/BtnUp";

export const PaverDetailPage = () => {
  const refSection = useRef(null);
  const scrollSection = () => {
    refSection.current.scrollIntoView({ behavior: "smooth" });
  };

  const { title } = useParams();
  const item = dataPaver.find((item) => item.title === title);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!item) {
    return <NotFoundPage />;
  }

  return (
    <>
      <section className={Styles["short-description"]}>
        <h2 className={Styles["title__short-description"]}>{item.title}</h2>
        <div className={Styles["content-hero__short-description"]}>
          <img src={item.image} alt={item.title} />
          <div className={Styles["text__short-description"]}>
            <div className={Styles["short-start"]}>
              <h3 className={Styles["title__short-start"]}>
                Коротко о технике
              </h3>
              <button
                className={Styles["btn__short-start"]}
                onClick={scrollSection}
              >
                Полные характеристики
              </button>
            </div>
            <div className={Styles["weight__short-description"]}>
              <table className={Styles["table__ttx"]}>
                <tbody>
                  <tr>
                    <td>Производительность, т/ч:</td>
                    <td className={Styles["td__ttx"]}>{item.layCapTheory}</td>
                  </tr>
                  <tr>
                    <td>Эксплутационная масса, т:</td>
                    <td className={Styles["td__ttx"]}>
                      {item.operatingWeight}
                    </td>
                  </tr>
                  <tr>
                    <td>Рабочая скорость, м/мин:</td>
                    <td className={Styles["td__ttx"]}>{item.workSpeed}</td>
                  </tr>
                  <tr>
                    <td>Емкость бункера, т:</td>
                    <td className={Styles["td__ttx"]}>
                      {item.bunkerContainer}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={Styles["btns__short-description"]}>
              <Link to={item.docs} className={Styles["btn__download-docs"]}>
                Скачать спецификацию
              </Link>
              <Link to="/contacts" className={Styles["btn__arrange"]}>
                Связаться с менеджером
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className={Styles["info"]}>
        <h2 className={Styles["title__info"]}>информация о технике</h2>
        <ul type="disc" className={Styles["text__info"]}>
          <li className={Styles["text__info_list"]}>{item.desc[0]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[1]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[2]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[3]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[4]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[5]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[6]}</li>
          <li className={Styles["text__info_list"]}>{item.desc[7]}</li>
          {item.desc[8] && (
            <li className={Styles["text__info_list"]}>{item.desc[8]}</li>
          )}
          {item.desc[9] && (
            <li className={Styles["text__info_list"]}>{item.desc[9]}</li>
          )}
          {item.desc[10] && (
            <li className={Styles["text__info_list"]}>{item.desc[10]}</li>
          )}
        </ul>
      </section>

      <section
        ref={refSection}
        className={Styles["specifications"]}
        id="specifications"
      >
        <h2 className={Styles["title__specifications"]}>
          характеристики техники
        </h2>
        <div className={Styles["table__specifications"]}>
          <table>
            <tr className={Styles["table__specifications_title"]}>
              <td>Система управления</td>
            </tr>
            <tbody>
              <tr className={Styles["table__specifications_line"]}>
                <td>Наименование системы управления:</td>
                <td>{item.systemControl}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Производительность</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Производительность укладки (теор.):</td>
                <td>{item.layCapTheory}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Максимальная толщина укладки, мм:</td>
                <td>{item.maxLayThick}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Максимальная ширина укладки, м:</td>
                <td>{item.minLayThick}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Ширина укладки</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Базовая, м:</td>
                <td>{item.basicLayThick}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Рабочая, гидравлическое уширение, м:</td>
                <td>{item.workHydraulicBroad}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Рабочая, максимальная, м:</td>
                <td>{item.workMax}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Рабочая, минимальная, м:</td>
                <td>{item.minMax}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Масса</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Эксплутационная масса, т:</td>
                <td>{item.operatingWeight}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Ходовая часть</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Рабочая скорость, м/мин:</td>
                <td>{item.workSpeed}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Транспортная скорость, км/ч:</td>
                <td>{item.transportSpeed}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Угол въезда, °:</td>
                <td>{item.entranceAngle}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Шасси</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>База (линия контакта гусеницы с основанием), мм:</td>
                <td>{item.chassisBase}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ширина гусеницы, мм:</td>
                <td>{item.trackWidth}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Бункер</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Управление:</td>
                <td>{item.bunkerManage}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ёмкость, т:</td>
                <td>{item.bunkerContainer}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ширина внутренняя, мм:</td>
                <td>{item.bunkerInnerWidth}</td>
              </tr>
              {item.bunkerHeightUnload && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Высота разгрузки, по центру/по краям, мм:</td>
                  <td>{item.bunkerHeightUnload}</td>
                </tr>
              )}

              <tr className={Styles["table__specifications_title"]}>
                <td>Транспортер</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Управление:</td>
                <td>{item.conveyorManage}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Ширина, мм:</td>
                <td>{item.conveyorWidth}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Шнек</td>
              </tr>
              {item.augerManage && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Управление:</td>
                  <td>{item.augerManage}</td>
                </tr>
              )}
              {item.augerDiam && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Диаметр, мм:</td>
                  <td>{item.augerDiam}</td>
                </tr>
              )}
              {item.augerHeightLift && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Регулировка высоты подъема, тип, мм:</td>
                  <td>{item.augerHeightLift}</td>
                </tr>
              )}

              <tr className={Styles["table__specifications_title"]}>
                <td>Двигатель</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Управление:</td>
                <td>{item.motorManage}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Производитель/модель:</td>
                <td>{item.motorModel}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Номинальная мощность, кВт (л.с.):</td>
                <td>{item.motorMinimalPower}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Электрическая система, В:</td>
                <td>{item.motorElectricSystem}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Нормы токсичности:</td>
                <td>{item.motorToxic}</td>
              </tr>
              <tr className={Styles["table__specifications_line"]}>
                <td>Емкость топливного бака, л:</td>
                <td>{item.motorFuelTank}</td>
              </tr>

              <tr className={Styles["table__specifications_title"]}>
                <td>Выглаживающая плита</td>
              </tr>
              {item.augerManage && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Модель, тип подогрева:</td>
                  <td>{item.smoothPlateModel}</td>
                </tr>
              )}
              {item.augerDiam && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>
                    Ширина базовая/с гидравлическим уширением/ максимальная, м:
                  </td>
                  <td>{item.widthSmoothPlate}</td>
                </tr>
              )}
              {item.augerHeightLift && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Частота трамбующего бруса, об/мин:</td>
                  <td>{item.freqRammingBeam}</td>
                </tr>
              )}
              {item.freqVibrator && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Частота вибратора, об/мин:</td>
                  <td>{item.freqRammingBeam}</td>
                </tr>
              )}
              {item.augerHeightLift && (
                <tr className={Styles["table__specifications_line"]}>
                  <td>Поперечный излом плиты, %:</td>
                  <td>{item.transverseFracturePlate}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      <section className={Styles["gabarits"]}>
        <h2 className={Styles["title__gabarits"]}>габаритные размеры</h2>
        <div className={Styles["content__gabarits"]}>
          <div className={Styles["images__gabarits"]}>
            <img src={item.imagesParameters[0]} alt="фото габаритов 1" />
            <img src={item.imagesParameters[1]} alt="фото габаритов 2" />
          </div>
          <div className={Styles["table__gabarits"]}>
            <table>
              <tbody>
                <tr>
                  <td>A. Опорная длина гусеницы:</td>
                  <td>{item.parameterA} мм</td>
                </tr>
                <tr>
                  <td>B1. Ширина максимальная:</td>
                  <td>{item.parameterB1} мм</td>
                </tr>
                <tr>
                  <td>B2. Ширина транспортная:</td>
                  <td>{item.parameterB2} мм</td>
                </tr>
                <tr>
                  <td>B3. Колея гусениц:</td>
                  <td>{item.parameterB3} мм</td>
                </tr>
                <tr>
                  <td>C1. Ширина гусеницы:</td>
                  <td>{item.parameterC1} мм</td>
                </tr>
                {item.parameterH1 && (
                  <tr>
                    <td>H1. Высота максимальная:</td>
                    <td>{item.parameterH1} мм</td>
                  </tr>
                )}
                <tr>
                  <td>H2. Высота транспортная:</td>
                  <td>{item.parameterH2} мм</td>
                </tr>
                <tr>
                  <td>H3. Высота разгрузочная:</td>
                  <td>{item.parameterH3} мм</td>
                </tr>
                <tr>
                  <td>L1. Длина общая:</td>
                  <td>{item.parameterL1} мм</td>
                </tr>
                {item.parameterL2 && (
                  <tr>
                    <td>L2. Длина транспортная:</td>
                    <td>{item.parameterL2} мм</td>
                  </tr>
                )}
                <tr>
                  <td>L3. Длина бункера:</td>
                  <td>{item.parameterL3} мм</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <BtnUp />
      </section>
    </>
  );
};
