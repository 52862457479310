export const dataOverload = [
  {
    id: 1,
    title: "MF2500CS",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/mf2500cs.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/overloaders/mf2500cs_ru.pdf",
    desc: [
      "Первоклассные эксплуатационные характеристики.",
      "Высокая надежность на протяжении всего жизненного цикла.",
      "Габаритная ширина — 2,55м, позволяет транспортировать перегружатель без дополнительных согласований и разрешений.",
      "Высокопроизводительная транспортерная система. Полная выгрузка материала массой 27т осуществляется всего за 35 секунд.",
      "Заниженная разгрузочная высота и увеличенный объем бункера.",
      "Форма створок бункера с раздельным управлением и встроенными гидравлическими фартуками исключает застой материала и температурную сегрегацию.",
      "В любое время возможна доукомплектация легкосъемным поворотным транспортером для загрузки двух асфальтоукладчиков или боковой подачи материала.",
      "Подъемная платформа позволяет оператору эффективно управлять выгрузкой материала.",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 4000,

    operatingWeight: 20,
    workSpeed: "0 - 25",
    transportSpeed: "0 - 4,0",
    entranceAngle: 10,

    chassisBase: 3900,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 13,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: 580,

    conveyorManage:
      "Резиновая армированная лента\n с приводными цепями и\n поперечными поддерживающими\n планками",
    conveyorWidth: 1200,

    motorManage:
      "Электронное, с бесступенчатой\n регулировкой оборотов и\n экономичным режимом EcoMode",
    motorModel: "Cummins QSB 6.7-C220",
    motorMinimalPower: "164 / (223)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 300,

    trackRefLength: 3900,
    widthMax: 3350,
    widthTransport: 2550,
    trackTrack: 2090,
    widthTrack: 320,
    heightWork: 4000,
    heightTransport: 3100,
    heightUnloading: 570,
    heightMaxRaisedPlatform: 5025,
    lengthAll: 9200,
    lengthBunkers: 2290,
    heightUnloadingMin: 2180,
    heightUnloadingMax: 2560,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs/mf2500cs_1.jpg",
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs/mf2500cs_2.jpg",
    ],
  },
  {
    id: 2,
    title: "MF2500CS-Imix",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/mf2500cs-imix.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/overloaders/mf2500cs_ru.pdf",
    desc: [
      "Система гомогенизации смеси – IMIX: доступная функция перемешивания материала в приемном бункере и в зоне выгрузки материала.",
      "Высокопроизводительная транспортерная система: широкий тоннель и усиленная поперечными планками транспортерная лента позволяет быстро и бережно выгрузить материал в асфальтоукладчик.",
      "Легкосъемный поворотный транспортер позволяет вести параллельную загрузку двух асфальтоукладчиков или осуществлять боковую подачу материала.",
      "Габаритная ширина — 2,55м, позволяет транспортировать перегружатель без дополнительных согласований.",
      "Форма створок бункера с раздельным управлением и встроенными гидравлическими фартуками исключает застой материала.",
      "Заниженная разгрузочная высота и увеличенный объем бункера.",
      "Механическая замковая система транспортерной ленты – быстрый ремонт и минимум простоя.",
      "Подъемная платформа позволяет оператору эффективно и безопасно управлять выгрузкой материала.",
      "Система световой коммуникации с водителями самосвалов Truck Assist – комфортная приемка материала даже в ночное время.",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 1000,

    operatingWeight: "21,5",

    workSpeed: "0 - 25",
    transportSpeed: "0 - 4,0",
    entranceAngle: 10,

    chassisBase: 3900,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 13,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: 580,

    conveyorManage:
      "Резиновая армированная лента\n с приводными цепями и\n поперечными поддерживающими планками",
    conveyorWidth: 1200,

    motorManage:
      "Электронное, с бесступенчатой\n регулировкой оборотов и\n экономичным режимом EcoMode",
    motorModel: "Cummins QSB 6.7-C220",
    motorMinimalPower: "165 / (223)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 300,

    trackRefLength: 3900,
    widthMax: 3350,
    widthTransport: 2550,
    trackTrack: 2090,
    widthTrack: 320,
    heightWork: 4000,
    heightTransport: 3100,
    heightUnloading: 580,
    heightMaxRaisedPlatform: 5025,
    lengthAll: 9200,
    lengthBunkers: 2280,
    heightUnloadingMin: 2180,
    heightUnloadingMax: 2560,
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs-imix/mf2500cs_imix_1.jpg",
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs-imix/mf2500cs_imix_2.jpg",
    ],
  },
  {
    id: 3,
    title: "MF2500CS+SW6500",
    image:
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/mf2500cs_sw6500.jpg",
    docs: "https://storage.yandexcloud.net/dst-storage/docs/overloaders/mf2500cs_ru.pdf",
    desc: [
      "Высокопроизводительная транспортерная система: широкий тоннель и усиленная поперечными планками транспортерная лента позволяет быстро и бережно выгрузить материал в асфальтоукладчик.",
      "Легкосъемный поворотный транспортер позволяет вести параллельную загрузку двух асфальтоукладчиков или осуществлять боковую подачу материала.",
      "Габаритная ширина — 2,55м, позволяет транспортировать перегружатель без дополнительных согласований.",
      "Заниженная разгрузочная высота и увеличенный объем бункера.",
      "Форма створок бункера с раздельным управлением и встроенными гидравлическими фартуками исключает застой материала.",
      "Механическая замковая система транспортерной ленты – быстрый ремонт и минимум простоя.",
      "Подъемная платформа позволяет оператору эффективно и безопасно управлять выгрузкой материала.",
      "Система световой коммуникации с водителями самосвалов Truck Assist – комфортная приемка материала даже в ночное время.",
    ],

    systemControl: "PLC, цифровая",

    layCapTheory: 2000,

    operatingWeight: "24,6",

    workSpeed: "0 - 25",
    transportSpeed: "0 - 4,0",
    entranceAngle: 10,

    chassisBase: 3900,
    trackWidth: 320,

    bunkerManage: "Раздельное управление\n левой и правой створками",
    bunkerContainer: 13,
    bunkerInnerWidth: 3292,
    bunkerHeightUnload: 580,

    conveyorManage:
      "Резиновая армированная лента\n с приводными цепями и\n поперечными поддерживающими планками",
    conveyorWidth: 1200,

    motorManage:
      "Электронное, с бесступенчатой\n регулировкой оборотов и\n экономичным режимом EcoMode",
    motorModel: "Cummins QSB 6.7-C220",
    motorMinimalPower: "166 / (223)",
    motorElectricSystem: 24,
    motorToxic: "ЕU 3А / ТIER 3",
    motorFuelTank: 300,

    trackRefLength: 3900,
    widthMax: 3350,
    widthTransport: 2550,
    trackTrack: 2090,
    widthTrack: 320,
    heightWork: 4000,
    heightTransport: 3100,
    heightUnloading: 580,
    heightMaxRaisedPlatform: 5025,
    lengthAll: 15284,
    lengthBunkers: 2280,
    heightUnloadingMin: 2180,
    heightUnloadingMax: 2560,
    heightUnloadingRotaryCon: "1322-3887",
    heightUnloadingNoRotaryCon: "2180-2560",
    imagesParameters: [
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs-sw6500%20/mf2500cs-sw6500_1.png",
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs-sw6500%20/mf2500cs-sw6500_4.png",
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs-sw6500%20/mf2500cs-sw6500_2.png",
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs-sw6500%20/mf2500cs-sw6500_3.png",
      "https://storage.yandexcloud.net/dst-storage/img/overloaders/drawings/mf2500cs-sw6500%20/mf2500cs-sw6500_5.png",
    ],
  },
];
