import { useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./PaverPage.module.css";
import { dataPaver } from "../../data/dataPaver";
import { BtnUp } from "../../components/UI/BtnUp/BtnUp";

export const PaverPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={Styles["catalog"]}>
        <h2 className={Styles["title__catalog"]}>асфальтоукладчики</h2>
        <div className={Styles["items__catalog"]}>
          {dataPaver.map((item) => (
            <Link
              to={`/pavers/${item.title}`}
              className={Styles["item__catalog"]}
              key={item.id}
            >
              <img src={item.image} alt={item.title} />
              <h4 className={Styles["title__item"]}>{item.title}</h4>
              <div className={Styles["ttx__item"]}>
                <div className={Styles["weight__ttx"]}>
                  <p>Базовая ширина укладки: </p>
                  <p>{item.basicLayThick} мм</p>
                </div>
                <div className={Styles["load__ttx"]}>
                  <p>Максимальная ширина укладки: </p>
                  <p>{item.workMax} м</p>
                </div>
                <div className={Styles["performance__ttx"]}>
                  <p>Производительность: </p>
                  <p>{item.layCapTheory} т/ч</p>
                </div>
              </div>
              <Link
                to={`/pavers/${item.title}`}
                className={Styles["item__btn"]}
              >
                подробнее
              </Link>
            </Link>
          ))}
        </div>
        <BtnUp />
      </section>
    </>
  );
};
