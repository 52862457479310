import Styles from "./MainScreen.module.css";

export const MainScreen = ({ categorySectionRef }) => {
  const handleScrollToCategory = () => {
    categorySectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <img
        className={Styles["main-screen__img"]}
        src="./img/other/main_screen.jpg"
        alt="главная картинка дст"
      />
      <h1 className={Styles["slogan__main-screen"]}>
        ваш надежный партнер в строительстве дорог
      </h1>
      <button
        onClick={handleScrollToCategory}
        className={Styles["catalog__btn"]}
      >
        каталог
      </button>
    </>
  );
};
