import { Routes, Route } from "react-router-dom";

import { Layout } from "../components/Layout/Layout";
import { Homepage } from "../pages/HomePage/HomePage";
import { RinkPage } from "../pages/RinkPage/RinkPage";
import { GroundRinkPage } from "../pages/GroundRinkPage/GroundRinkPage";
import { AsphaltRinkPage } from "../pages/AsphaltRinkPage/AsphaltRinkPage";
import { PaverPage } from "../pages/PaverPage/PaverPage";
import { OverloadPage } from "../pages/OverloadPage/OverloadPage";
import { ContactsPage } from "../pages/ContactsPage/ContactsPage";

import { RinkDetailPage } from "../pages/DetailPages/RinkDetailPage";
import { GroundRinkDetailPage } from "../pages/DetailPages/GroundRinkDetailPage";
import { AsphaltRinkDetailPage } from "../pages/DetailPages/AsphaltRinkDetailPage";
import { PaverDetailPage } from "../pages/DetailPages/PaverDetailPage";
import { OverloadDetailPage } from "../pages/DetailPages/OverloadDetailPage";
import { NotFoundPage } from "../pages/NotFoundPage/NotFoundPage";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Homepage />}></Route>
        <Route path="rinks" element={<RinkPage />}></Route>
        <Route path="rinks/ground" element={<GroundRinkPage />}></Route>
        <Route path="rinks/asphalt" element={<AsphaltRinkPage />}></Route>
        <Route path="rinks/:title" element={<RinkDetailPage />}></Route>
        <Route
          path="rinks/ground/:title"
          element={<GroundRinkDetailPage />}
        ></Route>
        <Route
          path="rinks/asphalt/:title"
          element={<AsphaltRinkDetailPage />}
        ></Route>
        <Route path="pavers" element={<PaverPage />}></Route>
        <Route path="pavers/:title" element={<PaverDetailPage />}></Route>
        <Route path="overloads" element={<OverloadPage />}></Route>
        <Route path="overloads/:title" element={<OverloadDetailPage />}></Route>
        <Route path="contacts" element={<ContactsPage />}></Route>
        <Route path="*" element={<NotFoundPage />}></Route>
      </Route>
    </Routes>
  );
};

export default Router;
