import Styles from "./Contacts.module.css";

export const Contacts = () => {
  return (
    <div className={Styles["contacts__section"]}>
      <div className={Styles["contacts__items"]}>
        <div className={Styles["contacts__item"]}>
          <h3 className={Styles["contacts__item_title"]}>Москва</h3>
          <h5 className={Styles["contacts__item_name"]}>
            ООО «ДСТ», офис продаж
          </h5>
          <address className={Styles["contacts__item_address"]}>
            141401, Московская область, г. Химки, Транспортный проезд, д. 2 +7
            (495) 775-25-85
          </address>
        </div>
        <div className={Styles["contacts__item"]}>
          <h3 className={Styles["contacts__item_title"]}>Санкт-Петербург</h3>
          <h5 className={Styles["contacts__item_name"]}>
            ООО «ДСТ», офис продаж
          </h5>
          <address className={Styles["contacts__item_address"]}>
            192289, г. Санкт-Петербург, пр-т Девятого Января, д. 3А, корп. 6,
            оф. 6 +7 (812) 335-06-26
          </address>
        </div>
        <div className={Styles["contacts__item"]}>
          <h3 className={Styles["contacts__item_title"]}>Екатеринбург</h3>
          <h5 className={Styles["contacts__item_name"]}>
            ООО «ДСТ», офис продаж
          </h5>
          <address className={Styles["contacts__item_address"]}>
            620138, г. Екатеринбург, ул. Чистопольская, д. 13, оф. 214 +7 (343)
            312-34-55
          </address>
        </div>
        <div className={Styles["contacts__item"]}>
          <h3 className={Styles["contacts__item_title"]}>Кемерово</h3>
          <h5 className={Styles["contacts__item_name"]}>
            ООО «ДСТ», офис продаж
          </h5>
          <address className={Styles["contacts__item_address"]}>
            650051, г. Кемерово, ул. 1-я Камышинская, д. 47А +7 (3842) 46-44-36
          </address>
        </div>
      </div>
    </div>
  );
};
